import React, { useEffect, useState } from "react";
import "./ModalCard.css";
import Image from "../assets/images/Modalicon.svg";
import { Button, Alert, Card } from "react-bootstrap";
import * as cusAlert from "./Alert.js";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import AddIcon from "../assets/images/add.svg";
import {
  biproductdetailsGet,
  biproductdetailsPost,
  resetExceptionError,
} from "../store/actions/index.js";
import UploadFiles from "./UploadFiles.js";
import Cancel from "../assets/images/Cancel.svg";
import { motion } from "motion/react";

const AddNewProduct = ({ setShowAddNewRHPO, product }) => {
  const [isShow, setIsShow] = useState(false);
  const selectedPermission = useSelector((state) => state.selectedPermission);

  const [status, setStatus] = useState([]);
  const [formData, setFormData] = useState({
    name: product ? product.prod_name : "",
    desc: product ? product.desc : "",
    prod_category: product ? product.prod_category : "",
    status: product ? product.isactive : "",
  });
  const dispatch = useDispatch();
  const biProductDetailsGetDetails = useSelector(
    (state) => state.biProductDetailsGetDetails
  );
  const isBIProductDetailsFetched = useSelector(
    (state) => state.isBIProductDetailsFetched
  );
  const bi_id = localStorage.getItem("bi_id");

  useEffect(() => {
    dispatch(biproductdetailsGet(bi_id));
  }, [bi_id, dispatch]);

  useEffect(() => {
    if (isBIProductDetailsFetched) {
      if (biProductDetailsGetDetails && biProductDetailsGetDetails.ok) {
        setStatus(biProductDetailsGetDetails.data.status_data || []); // Set status options
      } else {
        alert(biProductDetailsGetDetails.data || "Unable to fetch data");
      }
    }
  }, [isBIProductDetailsFetched, biProductDetailsGetDetails]);

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.prod_name || "",
        desc: product.desc || "",
        prod_category: product.prod_catg_name || "",
        status: product.isactive || "",
      });
    } else {
      setFormData({
        name: "",
        desc: "",
        prod_category: "",
        status: "",
      });
    }
  }, [product]);

  const openAlert = () => setIsShow(true);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (event) => {
    const { name, desc, status } = formData;
    if (name !== "") {
      dispatch(
        biproductdetailsPost(
          bi_id,
          name,
          "",
          desc,
          product ? "edit" : "add",
          product ? product.id : "",
          status
        )
      );
      setShowAddNewRHPO(false);
    } else {
      openAlert();
    }
  };

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 1 }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className={"sidenav"}
      >
        <div
          className="gtm-sidebar-header"
          style={{
            borderBottom: "1px solid lightgrey",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: "1",
              minWidth: "70%",
              maxWidth: "80%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              margin: "0",
              paddingTop: "4px",
              paddingLeft: "10px",
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
            title="Bi Details"
          >
            BI Product Details &gt;{" "}
            {product ? "Edit BI Product Details" : "Add New BI Product Details"}
          </div>
          <a
            href="#"
            className="closebtn"
            onClick={() => setShowAddNewRHPO(false)}
          >
            &times;
          </a>
        </div>
        <div className="gtm-sidebar-body">
          <Alert show={isShow} variant="warning">
            Name is Mandatory
          </Alert>
          <div>
            <div className="row">
              <div
                className="col-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  zIndex: 999,
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "20px",
                    marginBottom: 0,
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Product&nbsp;Name<span className="required">*</span>
                </div>
                <input
                  className="form-control"
                  id="productname"
                  value={formData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  disabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  zIndex: 999,
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "85px",
                    marginBottom: 0,
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Status
                </div>
                <Select
                  required
                  className="product-status"
                  placeholder="Select status"
                  options={status}
                  value={status.find(
                    (option) => option.value === formData.status.value
                  )}
                  onChange={(e) => handleInputChange("status", e)}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "var(--sub-heading-font-size)",
                        }}
                      >
                        {e.value}
                      </span>
                    </div>
                  )}
                  isDisabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "20px",
                    marginBottom: 0,
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Product Description
                </div>
              </div>
              <textarea
                style={{
                  marginLeft: "20px",
                  width: "96%",
                  fontSize: "var(--sub-heading-font-size)",
                }}
                className="form-control"
                id="productdescription"
                placeholder="Describe your product"
                rows={4}
                value={formData.desc}
                onChange={(e) => handleInputChange("desc", e.target.value)}
                disabled={
                  selectedPermission === "write" ||
                  selectedPermission === "admin"
                    ? false
                    : true
                }
              />
            </div>
            <div>
              <div
                style={{
                  marginLeft: "10px",
                  marginBottom: 0,
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Please upload product related documents eg: product deck, idea
                whitepapers etc.
              </div>
              <UploadFiles selectedSubMenuPermission={selectedPermission} />
              <Button
                type="button"
                className="save-button-style-rhpo"
                onClick={(e) => handleSubmit(e)}
                disabled={
                  selectedPermission === "write" ||
                  selectedPermission === "admin"
                    ? false
                    : true
                }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

const SelectableCard = ({
  icon,
  title,
  description,
  productid,
  isactive,
  onCardClick,
}) => {
  const dispatch = useDispatch();
  const [showButtons, setShowButtons] = useState(false);
  const [modalId, setModalId] = useState("");
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const handleMouseEnter = () => {
    setShowButtons(true);
  };
  const handleMouseLeave = () => {
    setShowButtons(false);
  };
  const handleOpenProductEditModal = (productid, e) => {
    onCardClick(productid);
    e.stopPropagation();
  };
  const handleProductRemove = (productid, e) => {
    setModalId(productid);
    cusAlert.default.warning({
      title: "Warning",
      text: "Are you sure do you want to Inactive the product?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          dispatch(
            biproductdetailsPost(
              localStorage.getItem("bi_id"),
              false,
              false,
              false,
              "delete",
              productid,
              false
            )
          );
          dispatch(resetExceptionError());
        }
      },
    });
    e.stopPropagation();
  };

  return (
    <>
      <Card>
        <div
          className="product-content"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => handleOpenProductEditModal(productid, e)}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img alt="icon" className="icon prdicon" src={icon} />
              <div style={{ display: "flex" }}>
                {selectedPermission === "admin" && (
                  <button
                    style={{
                      marginLeft: 5,
                      background: "white",
                      height: 17,
                      width: 17,
                      borderRadius: 17,
                      border: "thin ridge",
                      alignItems: "center",
                      justifyContent: "center",
                      display: showButtons ? "flex" : "none",
                    }}
                    type="button"
                    onClick={(e) => handleProductRemove(productid, e)}
                  >
                    <img alt="cancel" src={Cancel} width={20} height={20} />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className="overflow-ellipsis product-card-title"
            style={{
              marginLeft: "5px",
            }}
            data-value={title}
          >
            {title}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h5 className="description" data-value={description}>
              {description}
            </h5>
          </div>
          <div
            style={
              isactive === "Active"
                ? {
                    fontSize: "var(--captions-font-size)",
                    paddingLeft: "5px",
                    color: "var(--active-color)",
                  }
                : {
                    fontSize: "var(--captions-font-size)",
                    paddingLeft: "5px",
                    color: "var(--inactive-color)",
                  }
            }
          >
            {isactive}
          </div>
        </div>
      </Card>
    </>
  );
};

const Example = ({ cardContents }) => {
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [showAddNewRHPO, setShowAddNewRHPO] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const biProductDetailsGetDetails = useSelector(
    (state) => state.biProductDetailsGetDetails
  );
  const biProductDetailsPostDetails = useSelector(
    (state) => state.biProductDetailsPostDetails
  );
  const isBIProductDetailsFetched = useSelector(
    (state) => state.isBIProductDetailsFetched
  );
  const bi_id = localStorage.getItem("bi_id");

  useEffect(() => {
    dispatch(biproductdetailsGet(bi_id));
  }, [bi_id]);

  useEffect(() => {
    if (isBIProductDetailsFetched && biProductDetailsGetDetails) {
      if (biProductDetailsGetDetails.ok) {
        setList(biProductDetailsGetDetails.data.cus_prod_data || []);
      } else {
        alert(biProductDetailsGetDetails.data);
      }
    }
  }, [isBIProductDetailsFetched, biProductDetailsGetDetails]);

  useEffect(() => {
    if (biProductDetailsPostDetails && biProductDetailsPostDetails.data) {
      if (biProductDetailsPostDetails.data.action === "add") {
        const newList = list.concat({
          id: biProductDetailsPostDetails.data.key,
          icon: Image,
          prod_name: biProductDetailsPostDetails.data.product_name,
          prod_category: biProductDetailsPostDetails.data.product_ctg,
          prod_catg_name: biProductDetailsPostDetails.data.product_ctg_nm,
          isactive: {
            name: "Active",
            value: "Active",
          },
          desc: biProductDetailsPostDetails.data.desc,
          type: "normal",
        });
        setList(newList);
      } else if (biProductDetailsPostDetails.data.action === "edit") {
        setList(
          list.map((listItem) => {
            if (listItem.id === biProductDetailsPostDetails.data.key) {
              return {
                ...listItem,
                prod_name: biProductDetailsPostDetails.data.product_name,
                prod_category: biProductDetailsPostDetails.data.product_ctg,
                prod_catg_name: biProductDetailsPostDetails.data.product_ctg_nm,
                isactive: biProductDetailsPostDetails.data.isactive,
                desc: biProductDetailsPostDetails.data.desc,
              };
            } else {
              return listItem;
            }
          })
        );
      } else if (biProductDetailsPostDetails.data.action === "delete") {
        setList(
          list.map((listItem) => {
            if (listItem.id === biProductDetailsPostDetails.data.key) {
              return {
                ...listItem,
                isactive: biProductDetailsPostDetails.data.isactive,
              };
            } else {
              return listItem;
            }
          })
        );
        // const updatedList = list.filter(
        //   (item) => item.id !== biProductDetailsPostDetails.data.key
        // );
        // setList(updatedList);
      }
    }
  }, [biProductDetailsPostDetails]);

  const handleCardClick = (product) => {
    setSelectedProduct(product);
    setShowAddNewRHPO(true);
  };

  return (
    <>
      {list.map((cardContent, i) => {
        const { prod_name, prod_catg_name, type, id, desc, isactive } =
          cardContent;
        return (
          <div key={i}>
            {type === "normal" && (
              <SelectableCard
                key={i}
                icon={Image}
                title={prod_name}
                description={desc}
                type={type}
                productid={id}
                isactive={isactive.name}
                onCardClick={() => handleCardClick(cardContent)}
              />
            )}
          </div>
        );
      })}
      {cardContents[0].type === "dummy" && (
        <div className="cardlist">
          {(selectedPermission === "write" ||
            selectedPermission === "admin") && (
            <div className="cardmodal" onClick={() => handleCardClick("")}>
              <>
                <img alt="add" src={AddIcon} width={18} height={18} />
                <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
                  Add New Product
                </div>
              </>
            </div>
          )}
        </div>
      )}
      {showAddNewRHPO && (
        <AddNewProduct
          setShowAddNewRHPO={() => setShowAddNewRHPO(false)}
          product={selectedProduct}
        />
      )}
    </>
  );
};

export default Example;
