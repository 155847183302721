import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
import CATable from "../CATable";
import { menus } from "./VCData";
import "./CompetitorAnalysis.css";
import {
  getCompAnalDetails,
  getFeatureTypeDetails,
  postCompAnalDetails,
} from "../../store/actions";
import e from "cors";
import { useNavigate } from "react-router-dom";
import HelpInfo from "../../utils/helpInfoProviderComponent.js";
import keys from "../../utils/helpInfoKeys.json";
import CommentSection from "../../utils/CommentSection.js";
import Login from "../Login.js";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo.js";
import { motion } from "motion/react";

function CompetitorAnalysis() {
  const dispatch = useDispatch();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [currentDocURL, setCurrentDocURL] = useState("");
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);
  const navigate = useNavigate();
  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  const onProductSelectionChanged = (rating, id, key, feature_ty_id) => {
    dispatch(
      postCompAnalDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        id,
        key,
        rating,
        feature_ty_id
      )
    );
  };
  const handleBack = () => {
    navigate("/marketsize", { replace: true });
  };
  const handleClick = () => {
    navigate("/operatingplan", { replace: true });
  };
  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      style={{ width: "100%" }}
    >
      <div
        style={{
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        Compare your product features with other competitors
      </div>
      <div className="comp-analysis-legend">
        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li>
            <i className="fa fa-star" style={{ color: "gray" }}></i>: Feature
            N/A;
          </li>
          <li>
            <i className="fa fa-star"></i>: Very weak;
          </li>
          <li>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>: Weak;
          </li>
          <li>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>: Average;
          </li>
          <li>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>: Strong;
          </li>
          <li>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>: Very strong.
          </li>
        </ul>
      </div>
      <br />
      <CATable onProductSelectionChanged={onProductSelectionChanged} />
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </motion.div>
  );
  const rcontent = (
    <div style={{ height: "100%", width: "100%" }}>
      {currentDocURL && (
        <>
          <a
            style={{
              float: "right",
              marginBottom: 5,
              textDecoration: "none",
              border: 0,
              padding: 5,
              borderRadius: 10,
              background: "#0A5F59",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            target="_blank"
            href={currentDocURL}
          >
            Popout
          </a>
          <iframe height="100%" width="100%" src={currentDocURL} />
        </>
      )}
    </div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Strategy Validation"}
        breadcrumbChild={"Competitor Analysis"}
        documentName={"Competitor Analysis"}
        helpKey={keys["Competitor Analysis"].Name}
        image={"question"}
      />
    );
  }
}

export default CompetitorAnalysis;
