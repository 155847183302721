import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../../MainComponent";
import { useNavigate } from "react-router-dom";
import "./EcosystemMapping.css";
import EcMappingTasksidebar from "./EcMappingTasksidebar";
import {
  getEcosystemMappingSectionDetails,
  postEcosystemMappingSectionDetails,
} from "../../../store/actions";
import { url } from "../../../store/sagas/API.js";
import ChevronLeft from "../../../assets/images/Chevron-left.svg";
import Img from "../../../assets/images/f1.svg";
import ChevronRight from "../../../assets/images/Chevron-right.svg";
import { Button, Form } from "react-bootstrap";
import HelpInfo from "../../../utils/helpInfoProviderComponent.js";
import keys from "../../../utils/helpInfoKeys.json";
import CommentSection from "../../../utils/CommentSection.js";
import Login from "../../Login.js";
import SideNavHelpInfo from "../../../utils/SideNavHelpInfo.js";
import { motion } from "motion/react";

function EcosystemMapping() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const selectedBI = useSelector((state) => state.selectedBI);
  const [biName, setBIName] = useState(null);
  const [currentDocURL, setCurrentDocURL] = useState("");
  const [communityData, setCommunityData] = useState([]);
  const [SelectECMappingSidebar, setSelectECMappingSidebar] = useState(false);
  const [selectedSubSectionId, setSelectedSubSectionId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);
  const [editMode, setEditMode] = useState(null);
  const [edititemData, setEditItemData] = useState("");
  const [childrenId, setChildrenId] = useState("");
  const [seletedParentId, setSelectedParentId] = useState("");
  const [ChildrenData, setChildrenData] = useState([]);
  const [childName, setChildName] = useState("");
  const [subSectionName, setSubsectionName] = useState("");

  const fetchEcosystemMappingSectionData = useSelector(
    (state) => state.fetchEcosystemMappingSectionData
  );
  const fetchPostEcosystemMappingSectionData = useSelector(
    (state) => state.fetchPostEcosystemMappingSectionData
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(4);

  const totalPages = Math.ceil(
    communityData.reduce(
      (total, sections) =>
        total + Math.ceil(sections.sub_section.length / cardsPerPage),
      0
    )
  );
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  useEffect(() => {
    if (selectedBI) {
      setBIName(selectedBI.label);
    }
  }, [selectedBI]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1048) {
        setCardsPerPage(2); // For small screens and tablets
      } else {
        setCardsPerPage(4); // For desktop
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial setup based on screen width
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (fetchPostEcosystemMappingSectionData) {
      if (fetchPostEcosystemMappingSectionData.action === "add") {
        const newChild = {
          id: fetchPostEcosystemMappingSectionData.op_id,
          name: fetchPostEcosystemMappingSectionData.op_name,
          is_active: 1,
          is_master: 0,
        };

        const updatedSections = communityData.map((section) => {
          if (section.id === sectionId) {
            section.sub_section = section.sub_section.map((subsection) => {
              if (subsection.id === selectedSubSectionId) {
                subsection.childrens = subsection.childrens.map((child) => {
                  if (child.id === seletedParentId) {
                    // Add the new child to the parent child's childrens
                    child.childrens.push(newChild);
                  }
                  return child;
                });
              }
              return subsection;
            });
          }
          return section;
        });

        setCommunityData(updatedSections);
      } else if (fetchPostEcosystemMappingSectionData.action === "edit") {
        const updatedSections = communityData.map((section) => {
          if (section.id === sectionId) {
            section.sub_section = section.sub_section.map((subsection) => {
              if (subsection.id === selectedSubSectionId) {
                subsection.childrens = subsection.childrens.map((child) => {
                  if (child.id === seletedParentId) {
                    // Find and update the specific child
                    child.childrens = child.childrens.map((innerChild) => {
                      if (innerChild.id === childrenId) {
                        innerChild.name =
                          fetchPostEcosystemMappingSectionData.op_name;
                        innerChild.is_active = 1;
                        innerChild.is_master = 0;
                      }
                      return innerChild;
                    });
                  }
                  return child;
                });
              }
              return subsection;
            });
          }
          return section;
        });

        setCommunityData(updatedSections);
      } else if (fetchPostEcosystemMappingSectionData.action === "delete") {
        const updatedSections = [...communityData];
        // Find the section, subsection, and child based on their IDs
        const sectionIndex = updatedSections.findIndex(
          (s) => s.id === sectionId
        );

        if (sectionIndex !== -1) {
          const subSectionIndex = updatedSections[
            sectionIndex
          ].sub_section.findIndex((sub) => sub.id === selectedSubSectionId);

          if (subSectionIndex !== -1) {
            const ParentIndex = updatedSections[sectionIndex].sub_section[
              subSectionIndex
            ].childrens.findIndex((par) => par.id === seletedParentId);
            if (ParentIndex !== -1) {
              const childrenIndex = updatedSections[sectionIndex].sub_section[
                subSectionIndex
              ].childrens[ParentIndex].childrens.findIndex(
                (child) => child.id === childrenId
              );
              updatedSections[sectionIndex].sub_section[
                subSectionIndex
              ].childrens[ParentIndex].childrens.splice(childrenIndex, 1);
            }
          }
        }
        setCommunityData(updatedSections);
      }
    }
  }, [fetchPostEcosystemMappingSectionData]);

  useEffect(() => {
    if (
      fetchEcosystemMappingSectionData &&
      fetchEcosystemMappingSectionData.sections.length > 0
    ) {
      setCommunityData(fetchEcosystemMappingSectionData.sections);
    }
  }, [fetchEcosystemMappingSectionData]);

  useEffect(() => {
    dispatch(getEcosystemMappingSectionDetails(localStorage.getItem("bi_id")));
  }, []);

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  const handleBack = () => {
    navigate("/biproductdetails", { replace: true });
  };

  const handleClick = () => {
    navigate("/xlstrategymatrix", { replace: true });
  };
  const handleECMappingSidebar = (
    secId,
    childName,
    subsecId,
    subSecName,
    parentId,
    ChildrenData
  ) => {
    setChildrenData(ChildrenData);
    setSelectECMappingSidebar(true);
    setSelectedSubSectionId(subsecId);
    setChildName(childName);
    setSubsectionName(subSecName);
    setSectionId(secId);
    setSelectedParentId(parentId);
  };

  const handleTasksidebarClose = () => {
    setSelectECMappingSidebar(false);
  };

  const handleEdit = (index, child, parentId) => {
    setChildrenId(child.id);
    setEditMode(index);
    setSelectedParentId(parentId);
    setEditItemData(child.name);
  };
  const handleViewReport = () => {
    window.open("/reports", "_blank");
  };
  const content = (
    <>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: "var(--main-heading)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Ecosystem Mapping
            </div>
            <span style={{ margin: "5px" }}>
              <HelpInfo
                documentName={"Ecosystem Mapping"}
                helpKey={keys["Ecosystem Mapping"].Objective}
              />
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              top: "80px",
              right: "225px",
              zIndex: 1,
            }}
          >
            <Button
              onClick={() => handleViewReport()}
              className="ecosystem-map-view-report"
            >
              View Report
            </Button>
          </div>
          <br />
          {communityData && communityData.length > 0 ? (
            communityData.map((sections, index) => (
              <div key={index}>
                <div key={sections.id}>
                  <div
                    className="card-container"
                    style={{ display: "flex", marginTop: "5px" }}
                  >
                    {sections.sub_section &&
                      sections.sub_section.length > 0 &&
                      sections.sub_section
                        .slice(
                          (currentPage - 1) * cardsPerPage,
                          currentPage * cardsPerPage
                        )
                        .map((sub_section) => (
                          <div className="" key={sub_section.id}>
                            <div className="card-wrapper">
                              <div className="cards">
                                <div className="card-body">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {sub_section.icon ? (
                                      <img
                                        alt="icon"
                                        src={url + sub_section.icon}
                                      />
                                    ) : (
                                      <img alt="icon" src={Img} />
                                    )}
                                    <div className="eco-mapping-card-title ">
                                      {sub_section.name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {sub_section.childrens &&
                              sub_section.childrens.length > 0 &&
                              sub_section.childrens.map((child) => (
                                <div
                                  key={child.id}
                                  className="ec-mapping-child-card"
                                >
                                  <div
                                    className="sub_strategic_card"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      handleECMappingSidebar(
                                        sections.id,
                                        child.name,
                                        sub_section.id,
                                        sub_section.name,
                                        child.id,
                                        child
                                      )
                                    }
                                  >
                                    <div className="eco-mapping-card-sub-title ">
                                      {child.name}
                                    </div>
                                    <span>
                                      <img
                                        alt="chevronright"
                                        src={ChevronRight}
                                        width={20}
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                  </div>
                </div>
                <div className="pagination">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <span style={{ opacity: currentPage === 1 ? 0.3 : 1 }}>
                      <img alt="chevronleft" src={ChevronLeft} width={25} />
                    </span>
                  </button>
                  <span style={{ margin: "0px 5px" }}>
                    {currentPage} / {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <span
                      style={{ opacity: currentPage === totalPages ? 0.3 : 1 }}
                    >
                      <img alt="chevronright" src={ChevronRight} width={25} />
                    </span>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div style={{ fontSize: "var(--text-font-size)" }}>
              Master data not created yet.
            </div>
          )}
        </div>
        <div>
          <CommentSection />
          {selectedHelpInfoSidebar && <SideNavHelpInfo />}
        </div>
      </motion.div>

      {SelectECMappingSidebar && (
        <div>
          <EcMappingTasksidebar
            onClose={handleTasksidebarClose}
            setCommunityData={(value) => setCommunityData(value)}
            handleEdit={(index, childId, parentId) =>
              handleEdit(index, childId, parentId)
            }
            editMode={editMode}
            setEditItemData={(value) => {
              setEditItemData(value);
            }}
            edititemData={edititemData}
            setEditMode={(value) => setEditMode(value)}
            seletedParentId={seletedParentId}
            ChildrenData={ChildrenData}
            childName={childName}
            subSectionName={subSectionName}
          />
        </div>
      )}
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Onboarding"}
        breadcrumbChild={"Ecosystem Mapping"}
        documentName={"Ecosystem Mapping"}
        helpKey={keys["Ecosystem Mapping"].Name}
        image={"question"}
      />
    );
  }
}

export default EcosystemMapping;

export const Reports = () => {
  const dispatch = useDispatch();
  const [communityData, setCommunityData] = useState([]);
  const fetchEcosystemMappingSectionData = useSelector(
    (state) => state.fetchEcosystemMappingSectionData
  );
  useEffect(() => {
    dispatch(getEcosystemMappingSectionDetails(localStorage.getItem("bi_id")));
  }, []);
  useEffect(() => {
    if (
      fetchEcosystemMappingSectionData &&
      fetchEcosystemMappingSectionData.sections.length > 0
    ) {
      setCommunityData(fetchEcosystemMappingSectionData.sections);
    }
  }, [fetchEcosystemMappingSectionData]);

  return (
    <div class="eco-column-container">
      {communityData.map((root) => (
        <>
          <h3 key={root.id} className="eco_reports_root_name">
            {root.name}
          </h3>
          {root.sub_section.length > 0 &&
            root.sub_section.map((subSection) => (
              <>
                <ol
                  key={subSection.id}
                  className="eco_reports_list eco_reports_subsection"
                >
                  <li className="name">{subSection.name}</li>
                </ol>
                {subSection.childrens.length > 0 &&
                  subSection.childrens.map((child) => (
                    <>
                      <div>
                        <ol
                          key={child.id}
                          className="eco_reports_list  eco_reports_child"
                        >
                          <li className="name">{child.name}</li>
                        </ol>
                      </div>

                      {child.childrens.length > 0 ? (
                        child.childrens.map((subChild) => (
                          <>
                            <ol
                              key={subChild.id}
                              className="eco_reports_list  eco_reports_subchild"
                            >
                              <li>{subChild.name}</li>
                            </ol>
                          </>
                        ))
                      ) : (
                        <div className="eco_reports_no_data">no data</div>
                      )}
                    </>
                  ))}
              </>
            ))}
        </>
      ))}
    </div>
  );
};
