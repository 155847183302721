import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as cusAlert from "./Alert.js";
import Select from "react-select";
import {
  getCompetitorDetails,
  getFeatureTypeDetails,
  postCompetitorDetails,
  postFeatureDetails,
} from "../store/actions";
import { getCompAnalDetails } from "../store/actions";
import { Button } from "react-bootstrap";
import Cancel from "../assets/images/Cancel.svg";
import Add from "../assets/images/add.svg";
import AddColorIcon from "../assets/images/Plus.svg";
import { motion } from "motion/react";
import ReactStars from "react-rating-stars-component";

const CATable = (props) => {
  const dispatch = useDispatch();
  const { selectedMenu } = props;

  const tableRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [tableWidth, setTableWidth] = useState(150);
  const [tableHeight, setTableHeight] = useState(150);
  const [showFeaturePopup, setShowFeaturePopup] = useState(false);
  const [showCompetitorPopup, setShowCompetitorPopup] = useState(0);
  const [competitor, setCompetitor] = useState("");
  const [rows, setRows] = useState([{ id: "", input: "", selector: null }]);
  const isAddRowDisabled = rows?.some((row) => row.input === "");
  const selectedPermission = useSelector((state) => state.selectedPermission);
  // const [featureTypeData, setFeatureTypeData] = useState([]);
  const [competitorList, setCompetitorList] = useState([]);
  const [competitorAdded, setCompetitorAdded] = useState(false);
  const [competitorSaved, setCompetitorSaved] = useState(false);
  const [competitorUpdated, setCompetitorUpdated] = useState(false);
  const [compAnalFetched, setCompAnalFetched] = useState(false);
  const isCompetitorAdded = useSelector((state) => state.isCompetitorAdded);
  const isCompetitorSaved = useSelector((state) => state.isCompetitorSaved);
  const isCompetitorUpdated = useSelector((state) => state.isCompetitorUpdated);
  const getCompetitorData = useSelector((state) => state.getCompetitorData);
  const isCompAnalFetched = useSelector((state) => state.isCompAnalFetched);
  const isFeaturePosted = useSelector((state) => state.isFeaturePosted);
  const getFeatureTypeData = useSelector((state) => state.getFeatureTypeData);
  const featureID = useSelector((state) => state.featureID);
  const [initialCompetitorList, setInitialCompetitorList] = useState(false);
  const [initialFeatureList, setInitialFeatureList] = useState(false);
  const [isFeatureDetailsUpdated, setIsFeatureDetailsUpdated] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [maxButtonHeight, setMaxButtonHeight] = useState(0);
  const [maxButtonWidth, setMaxButtonWidth] = useState(0);
  const closeModal = () => setShowFeaturePopup(false);
  const closeCompetitorModal = () => setShowCompetitorPopup(false);
  const isFeatureDetailsFeched = useSelector(
    (state) => state.isFeatureDetailsFeched
  );
  const isFeatureTypeDetailsFetched = useSelector(
    (state) => state.isFeatureTypeDetailsFetched
  );

  const postedCompAnalData = useSelector((state) => state.postedCompAnalData);
  const isFeatureDetailsPosted = useSelector(
    (state) => state.isFeatureDetailsPosted
  );
  const [featureDetailsPost, setFeatureDetailsPost] = useState(false);

  const isCompAnalDetailsFetched = useSelector(
    (state) => state.isCompAnalDetailsFetched
  );
  const getCompAnalData = useSelector((state) => state.getCompAnalData);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const isCompetitorDetailsFetched = useSelector(
    (state) => state.isCompetitorDetailsFetched
  );
  const isCompetitorDetailsAdded = useSelector(
    (state) => state.isCompetitorDetailsAdded
  );
  const isCompetitorDetailsSaved = useSelector(
    (state) => state.isCompetitorDetailsSaved
  );
  const tableDivRef = useRef(null);
  useEffect(() => {
    dispatch(
      getCompAnalDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
    dispatch(
      getCompetitorDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);
  useEffect(() => {
    if (isCompetitorDetailsAdded) {
      setCompetitorUpdated(!competitorUpdated);
      dispatch(
        getCompetitorDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          competitorUpdated
        )
      );
    }
  }, [isCompetitorDetailsAdded, isCompetitorAdded]);
  useEffect(() => {
    // console.log(isCompetitorUpdated);
    if (isCompetitorDetailsFetched) {
      if (getCompetitorData) {
        setInitialCompetitorList(getCompetitorData.cust_choice);
        setCompetitorList(getCompetitorData.cust_choice);
      }
    }
  }, [isCompetitorDetailsFetched, isCompetitorUpdated]);

  useEffect(() => {
    dispatch(
      getFeatureTypeDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);

  useEffect(() => {
    if (isFeatureTypeDetailsFetched) {
      if (getFeatureTypeData) {
        if (getFeatureTypeData.message[0].feature_data) {
          setInitialFeatureList(
            getFeatureTypeData.message[0].feature_data.map((obj) => {
              return {
                id: obj.value,
                input: obj.label,
                // selector: obj.feature_type[0],
              };
            })
          );
          setRows(
            getFeatureTypeData.message[0].feature_data.map((obj) => {
              return {
                id: obj.value,
                input: obj.label,
                // selector: obj.feature_type[0],
              };
            })
          );
        }
        // if (getFeatureTypeData.message[0].feature_type) {
        //   setFeatureTypeData(
        //     getFeatureTypeData.message[0].feature_type.map((obj) => {
        //       return {
        //         value: obj.name,
        //         label: obj.feature_type,
        //       };
        //     })
        //   );
        // }
      }
    }
  }, [isFeatureTypeDetailsFetched, isFeatureDetailsFeched]);
  useEffect(() => {
    if (isCompetitorDetailsSaved) {
      setCompAnalFetched(!compAnalFetched);
      dispatch(
        getCompAnalDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          compAnalFetched
        )
      );
    }
  }, [isCompetitorDetailsSaved, isCompetitorSaved, featureID]);
  useEffect(() => {
    dispatch(
      getCompAnalDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        true
      )
    );
  }, [postedCompAnalData]);
  useEffect(() => {
    if (isFeatureDetailsPosted) {
      setCompAnalFetched(!compAnalFetched);
      dispatch(
        getCompAnalDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          compAnalFetched
        )
      );
      setIsFeatureDetailsUpdated(!isFeatureDetailsUpdated);
      dispatch(
        getFeatureTypeDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          isFeatureDetailsUpdated
        )
      );
    }
  }, [isFeatureDetailsPosted, isFeaturePosted]);
  useEffect(() => {
    if (isCompAnalDetailsFetched) {
      if (getCompAnalData) {
        setIsDataAvailable(true);
        setColumns(getCompAnalData.columns);
        setData(getCompAnalData.data);
      }
    }
  }, [isCompAnalDetailsFetched, isCompAnalFetched]);
  const handleAddFeature = () => {
    setShowFeaturePopup(true);
    setShowCompetitorPopup(false);
  };
  const handleAddCompetitor = () => {
    setShowCompetitorPopup(true);
    setShowFeaturePopup(false);
  };
  const handleFeaturePopupClose = () => {
    setShowFeaturePopup(false);
    setRows(initialFeatureList ? initialFeatureList : rows);
  };
  const handleCompetitorPopupClose = () => {
    setShowCompetitorPopup(false);
    setCompetitorList(initialCompetitorList);
  };
  // const handleFeatureTypeSelect = (id, e) => {
  //   const updatedRows = rows.map((row) => {
  //     if (row.id === id) {
  //       return { ...row, selector: e };
  //     }
  //     return row;
  //   });
  //   setRows(updatedRows);
  // };
  const handleInputChange = (id, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, input: value };
      }
      return row;
    });
    setRows(updatedRows);
  };
  // const customFilter = (option, searchText) => {
  //   if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  useEffect(() => {
    const calculateColumnWidths = () => {
      if (tableDivRef) {
        setMaxButtonHeight(tableDivRef.current.offsetHeight);
        // console.log(tableDivRef.current.offsetHeight);
        setMaxButtonWidth(tableDivRef.current.offsetWidth);
      }
      const thElements = tableRef.current.querySelectorAll("th");
      setTableWidth(tableRef.current.offsetWidth);
      setTableHeight(tableRef.current.offsetHeight);
      const newColumnWidths = {};
      thElements.forEach((th) => {
        const key = th.getAttribute("data-key");
        const width = `${th.offsetWidth}px`;
        newColumnWidths[key] = width;
      });

      setColumnWidths(newColumnWidths);
    };

    window.addEventListener("resize", calculateColumnWidths);
    calculateColumnWidths();

    return () => {
      window.removeEventListener("resize", calculateColumnWidths);
    };
  }, [columns]);
  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: 0,
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#ecf4f3" : "white",
  //     color: "black",
  //   }),
  // };
  const handleAddRow = () => {
    const newId = String(Date.now());
    const newRow = { id: newId, input: "", selector: null };
    const updatedRows = [newRow, ...rows];
    setRows(updatedRows);
  };

  const handleSaveFeature = () => {
    const formattedData = rows.map((row) => ({
      id: row.id,
      feature_name: row.input,
      // feature_type: row.selector ? row.selector.value : null,
      feature_type: null,
    }));
    const allItemsValid = formattedData.every(
      // (item) => item.feature_name !== "" && item.feature_type !== null
      (item) => item.feature_name !== ""
    );
    if (allItemsValid) {
      setFeatureDetailsPost(!featureDetailsPost);
      dispatch(
        postFeatureDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          formattedData,
          featureDetailsPost
        )
      );
      setShowFeaturePopup(false);
    }
  };

  const handleAddCompetitorData = () => {
    if (competitor !== "") {
      setCompetitorAdded(!competitorAdded);
      var check = false;
      dispatch(
        postCompetitorDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          [{ competitor_name: competitor }],
          null,
          competitorAdded,
          competitorSaved,
          true,
          false
        )
      );
    }
    setCompetitor("");
  };
  const handleRemoveCompetitor = (key) => {
    cusAlert.default.warning({
      title: "Warning",
      // html: ISError,
      text: "Are you sure do you want to delete the competitor?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          let itemsArray = [...competitorList];
          itemsArray = itemsArray.map((c) => {
            if (c.key == key) {
              c = { ...c, isactive: false };
            }
            return c;
          });
          let newArray = [...itemsArray];
          setCompetitorList(newArray);
          dispatch(
            postCompetitorDetails(
              localStorage.getItem("bi_id"),
              localStorage.getItem("product_id"),
              null,
              newArray,
              competitorAdded,
              competitorSaved,
              false,
              true
            )
          );
        }
      },
    });
  };
  const handleSaveCompetitorData = () => {
    setCompetitorSaved(!competitorSaved);
    dispatch(
      postCompetitorDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        null,
        competitorList,
        competitorAdded,
        competitorSaved,
        false,
        true
      )
    );
    setShowCompetitorPopup(false);
  };
  const handleRemoveFearure = (id) => {
    cusAlert.default.warning({
      title: "Warning",
      // html: ISError,
      text: "Are you sure do you want to delete the Feature?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          dispatch(
            postFeatureDetails(null, null, null, featureDetailsPost, id)
          );
        }
      },
    });
  };
  return (
    <>
      <div style={{ height: "70%" }}>
        {/* <div style={{ height: "100%"}}> */}
        <div style={{ marginTop: 10 }}>
          {isDataAvailable &&
            (selectedPermission === "write" ||
              selectedPermission === "admin") && (
              <button
                className="newfeature-button"
                style={{
                  backgroundColor: "white",
                  border: "thin ridge",
                  width: tableWidth,
                  maxWidth: maxButtonWidth,
                  textWrap:'nowrap',
                  height: 36,
                  borderRadius: 10,
                  marginLeft: "45px",
                }}
                onClick={handleAddCompetitor}
                disabled={!data.length > 0}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={Add} width={17} height={17} />
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--text-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    New Competitor
                  </span>
                </div>
              </button>
            )}
        </div>
        <div style={{ display: "flex" }}>
          {showFeaturePopup ? (
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 1 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              className={"sidenav"}
            >
              <div
                className="gtm-sidebar-header"
                style={{
                  display: "flex",
                  borderBottom: "1px solid lightgrey",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                    paddingLeft: "10px",
                  }}
                >
                  Competitor Analysis &gt; New Feature
                </div>

                <a href="#" className="closebtn" onClick={closeModal}>
                  &times;
                </a>
              </div>
              <div
                style={{
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                  padding: "10px",
                  marginLeft: "10px",
                }}
              >
                What are the main criteria or features against which we need to
                assess the competition?
              </div>

              {/* <div className="modal-overlay">
              <div
                className="modal-content"
                style={{ height: "70%", width: 500 }}
              > */}

              <div style={{ height: "100%", overflow: "auto" }}>
                <button
                  className="new-feature-button"
                  style={{ backgroundColor: "white", border: 0 }}
                  onClick={handleAddRow}
                  disabled={isAddRowDisabled}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <img src={AddColorIcon} />
                    <span
                      style={{
                        paddingLeft: "5px",
                        color: isAddRowDisabled
                          ? "#7F9E9C"
                          : "var(--card-text-color)",
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      Add new
                    </span>
                  </div>
                </button>

                <table
                  className="ca-table"
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    width: "96%",
                    margin: "auto",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #ddd",
                          padding: "8px 16px",
                          fontSize: "var(--sub-heading-font-size)",
                          textAlign: "left",
                        }}
                      >
                        No.
                      </th>
                      <th
                        style={{
                          fontSize: "var(--sub-heading-font-size)",
                          padding: "8px 16px",
                          textAlign: "left",
                        }}
                      >
                        Feature Name
                      </th>
                      {/* <th
                        style={{
                          fontSize: "var(--sub-heading-font-size)",
                          padding: "8px 16px",
                        }}
                      >
                        Feature Type
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index} style={{ padding: "8px 16px" }}>
                        <td style={{ padding: "8px 16px", textAlign: "left" }}>
                          {index + 1}.
                        </td>
                        <td style={{ padding: "8px 16px" }}>
                          <input
                            type="text"
                            value={row.input}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleInputChange(row.id, e.target.value)
                            }
                          />
                        </td>
                        {/* <td style={{ margin: "0px", padding: "0px" }}>
                          <Select
                            required
                            name="featuretype"
                            styles={customStyles}
                            className=" selectft"
                            placeholder="Select Feature Type"
                            value={row.selector}
                            maxMenuHeight={200}
                            options={featureTypeData}
                            onChange={(selectedFeatureType) =>
                              handleFeatureTypeSelect(
                                row.id,
                                selectedFeatureType
                              )
                            }
                            isSearchable={true}
                            filterOption={customFilter}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                              </div>
                            )}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Button
                type="button"
                className="save-button-style-rhpo"
                onClick={(e) => handleSaveFeature()}
              >
                Save
              </Button>
            </motion.div>
          ) : (
            // </div>
            ""
          )}
          {showCompetitorPopup ? (
            // <div className="modal-overlay">
            //   <div className="modal-content" style={{ height: 500 }}>
            //     <h5>
            //       Mention the competitors that the features will be mapped
            //       against?
            //     </h5>
            //     <hr style={{ borderTop: "1px solid" }} />
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 1 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              className={"sidenav"}
            >
              <div
                className="gtm-sidebar-header"
                style={{
                  display: "flex",
                  borderBottom: "1px solid lightgrey",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                    paddingLeft: "10px",
                  }}
                >
                  Competitor Analysis &gt; New Competitor
                </div>
                <a href="#" className="closebtn" onClick={closeCompetitorModal}>
                  &times;
                </a>
              </div>
              <div
                style={{
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                  padding: "10px",
                  marginLeft: "10px",
                }}
              >
                Mention the competitors that the features will be mapped
                against?
              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <input
                    className="form-control"
                    type="text"
                    style={{
                      height: 30,
                      width: "100%",
                      borderRadius: 5,
                      paddingLeft: 10,
                      margin: 0,
                      fontSize: "var(--text-font-size)",
                    }}
                    value={competitor}
                    onChange={(e) => setCompetitor(e.target.value)}
                  />
                </div>
                <button
                  style={{
                    color: "white",
                    borderRadius: 5,
                    border: 0,
                    fontSize: "var(--sub-heading-font-size)",
                    display: "flex",
                    padding: "4px 10px",
                    width: "fit-content",
                    backgroundColor: "var(--button-primary-color)",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                  onClick={(e) => handleAddCompetitorData()}
                >
                  Add
                </button>
              </div>
              <div style={{ margin: "0px 20px" }}>
                <div style={{ height: "100%", overflow: "auto" }}>
                  <table className="ca-table">
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "8px 16px",
                            fontSize: "var(--sub-heading-font-size)",
                            textAlign: "left",
                          }}
                        >
                          No.
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid #ddd",
                            fontSize: "var(--sub-heading-font-size)",
                            textAlign: "left",
                            padding: "8px 16px",
                          }}
                        >
                          Competitor Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {competitorList &&
                        competitorList
                          .filter((obj) => obj.isactive === true)
                          .map((obj, i) => (
                            <tr
                              key={i}
                              style={{ borderBottom: "1px solid #ddd" }}
                            >
                              <td
                                style={{
                                  padding: "8px 16px",
                                  textAlign: "left",
                                }}
                              >
                                {i + 1}.
                              </td>
                              <td
                                style={{
                                  padding: "8px 16px",
                                  textAlign: "left",
                                }}
                              >
                                {obj.competitor_name}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <Button
                type="button"
                className="save-button-style-rhpo"
                onClick={(e) => handleSaveCompetitorData()}
              >
                Save
              </Button>
            </motion.div>
          ) : (
            ""
          )}
          {isDataAvailable &&
            (selectedPermission === "write" ||
              selectedPermission === "admin") && (
              <button
                className="newcompetitor-button"
                style={{
                  backgroundColor: "white",
                  border: "thin ridge",
                  height: tableHeight,
                  maxHeight: maxButtonHeight,
                  minHeight: 150,
                  width: 36,
                  borderRadius: 10,
                  position: showCompetitorPopup
                    ? ""
                    : showFeaturePopup
                    ? ""
                    : "relative",
                  overflow: "hidden",
                  marginRight: "10px",
                }}
                onClick={handleAddFeature}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    writingMode: "vertical-rl",
                    transform: "rotate(180deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img src={Add} width={17} height={17} />
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "var(--text-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      fontWeight: "600",
                      display: "inline-block",
                    }}
                  >
                    New Feature
                  </span>
                </div>
              </button>
            )}

          <div className="ca-table-div" ref={tableDivRef}>
            <table ref={tableRef} className="bordered-ca-table">
              <thead style={{ position: "sticky", zIndex: "2" }}>
                <tr>
                  {!columns && (
                    <th
                      style={{
                        width: 200,
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      Features
                    </th>
                  )}
                  {columns &&
                    columns.map((column, index) => (
                      <th
                        style={{
                          padding: 5,
                          textAlign: column.key == "id" ? "left" : "center",
                          fontWeight: 400,
                          position: "sticky",
                          width: "130px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        key={column.key}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "130px",
                            overflow: "hidden",
                          }}
                        >
                          <span
                            style={{
                              flex: 1,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {column.name}
                          </span>
                          {index >= 1 && selectedPermission === "admin" && (
                            <button
                              style={{
                                margin: 5,
                                background: "white",
                                height: 17,
                                width: 17,
                                borderRadius: 17,
                                border: "thin ridge",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                marginTop: 3,
                              }}
                              type="button"
                              onClick={(e) =>
                                handleRemoveCompetitor(column.key)
                              }
                            >
                              <img src={Cancel} width={18} height={18} />
                              {/* <span style={{ fontSize: 12, paddingBottom: 2 }}>
                                x
                              </span> */}
                            </button>
                          )}
                        </div>
                      </th>
                    ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item) => (
                    <tr key={item.id} style={{ flexShrink: 0 }}>
                      {columns.map((column) => (
                        <td
                          style={{
                            paddingTop: 10,
                            paddingRight: column.key === "id" ? 10 : "",
                            width: columnWidths[column.key],
                          }}
                          key={column.key}
                          data-key={column.key}
                          className={column.key === "id" ? "products" : ""}
                        >
                          {column.key === "id" ? (
                            <div style={{ display: "flex" }}>
                              <label
                                style={{
                                  fontSize: "var(--sub-heading-font-size)",
                                  textOverflow: "ellipsis",
                                  textWrap: "nowrap",
                                  overflow: "hidden",
                                  width: "150px",
                                }}
                              >
                                {item.feature_name}
                              </label>
                              {selectedPermission === "admin" && (
                                <button
                                  style={{
                                    marginLeft: 20,
                                    background: "white",
                                    height: 17,
                                    width: 17,
                                    borderRadius: 17,
                                    border: "thin ridge",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    marginTop: 3,
                                    float: "right",
                                  }}
                                  type="button"
                                  onClick={(e) => handleRemoveFearure(item.id)}
                                >
                                  <img src={Cancel} width={18} height={18} />
                                </button>
                              )}
                            </div>
                          ) : (
                            <>
                              <div data-key={column.key}>
                                <ReactStars
                                  value={item[column.key]}
                                  count={5}
                                  onChange={(e) => {
                                    if (
                                      selectedPermission === "write" ||
                                      selectedPermission === "admin"
                                    ) {
                                      props.onProductSelectionChanged(
                                        e,
                                        item.id,
                                        column.key,
                                        item.feature_type_id
                                      );
                                    }
                                  }}
                                  size={24}
                                  activeColor="#ffd700"
                                />
                              </div>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default CATable;
