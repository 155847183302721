import React from "react";

import { Container } from "react-bootstrap";
import Example from "../../ModalCard";
import MainComponent from "../../MainComponent.js";
import { useNavigate } from "react-router-dom";
import keys from "../../../utils/helpInfoKeys.json";
import CommentSection from "../../../utils/CommentSection.js";
import Login from "../../Login.js";
import SideNavHelpInfo from "../../../utils/SideNavHelpInfo.js";
import { useSelector } from "react-redux";
import { motion } from "motion/react";

function BIProductDetails() {
  const navigate = useNavigate();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const teams = [
    {
      type: "dummy",
    },
  ];
  const handleClick = () => {
    if (localStorage.getItem("bi_id")) {
      var products = document.getElementsByClassName("product-content");
      if (products.length) {
        navigate("/esmapping", { replace: true });
      } else {
        return <div>{alert("Please add Product Details")}</div>;
      }
    } else {
      return <div>{alert("Please select Business Initiative")}</div>;
    }
  };
  const handleBack = () => {
    navigate("/bidetails", { replace: true });
  };
  var content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{y:'100%'}}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      <div
        style={{
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        Enter the BI product details
      </div>
      <br />
      <div>
        <div style={{ display: "flex" }}>
          <Example cardContents={teams} />
        </div>
        <CommentSection />
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
    </motion.div>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          handleNext={handleClick}
          handleBackButton={handleBack}
          showSelector={true}
          showButtons={true}
          breadcrumbParent={"Onboarding"}
          breadcrumbChild={"BI Product Details"}
          documentName={"BI Product Details"}
          helpKey={keys["BI Product Details"].Name}
          image={"question"}
        />
      </>
    );
  }
}

export default BIProductDetails;
