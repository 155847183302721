import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "./MainComponent";
import { Form, FormCheck } from "react-bootstrap";
import { getNotes, postNotes } from "../store/actions";
import "./Notes.css";
import React, { useRef } from "react";
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { AiOutlineClose } from "react-icons/ai";
import { BiChevronsDown, BiChevronsUp } from "react-icons/bi";
import {
  AiOutlineCaretUp,
  AiOutlineCaretDown,
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
} from "react-icons/ai";
import TabIcon from "../assets/images/new-tab-icon.svg";
import { useNavigate } from "react-router-dom";
import QuillEditor from "../utils/QuillEditor";
import Login from "./Login";
import { motion } from "motion/react";

function Notes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentDocURL, setCurrentDocURL] = useState(
    "https://docs.google.com/document/d/1vliqjd6M3hEx3vt90z39LPo0Zxe-1ThEjqjBMYe_FOc/edit"
  );
  const newMeetingClicked = localStorage.getItem("setNewMeetingClicked");
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);
  const isNotesDataFetched = useSelector((state) => state.isNotesDataFetched);
  const isGetActionChanged = useSelector((state) => state.isGetActionChanged);
  const getNotesData = useSelector((state) => state.getNotesData);
  const [notesData, setNotesData] = useState([]);
  const [notesName, setNotesName] = useState("");
  const [leftElementOnChange, setLeftElementOnChange] = useState(false);
  const [notesOnChange, setNotesOnChange] = useState(false);
  const [notesNameOnChange, setNotesNameOnChange] = useState(false);
  const [notesContent, setNotesContent] = useState("");
  const [PLOnChange, setPLOnChange] = useState(false);
  const [PLContent, setPLContent] = useState("");
  const [AcIOnChange, setAcIOnChange] = useState(false);
  const [AcIContent, setAcIContent] = useState("");
  const [DrBOnChange, setDrBOnChange] = useState(false);
  const [DrBContent, setDrBContent] = useState([]);
  const [agendaOnChange, setAgendaOnChange] = useState(false);
  const [agendaContent, setAgendaContent] = useState("");
  const [dtOnChange, setDTOnChange] = useState(false);
  const [dtContent, setDTContent] = useState("");
  const [issuesOnChange, setIssuesOnChange] = useState(false);
  const [issuesContent, setIssuesContent] = useState("");
  const [action, setAction] = useState("note");
  const [actionChange, setActionChange] = useState(false);
  const [notesItemOpen, setNotesItemOpen] = useState(false);
  const [maxmizeNotesItem, setMaxmizeNotesItem] = useState(true);
  const [agendaItemOpen, setAgendaItemOpen] = useState(true);
  const [maxmizeAgendaItem, setMaxmizeAgendaItem] = useState(false);
  const [PLItemOpen, setPLItemOpen] = useState(false);
  const [maxmizePLItemOpen, setMaxmizePLItemOpen] = useState(false);
  const [acItemOpen, setAcItemOpen] = useState(false);
  const [maxmizeAcItemOpen, setMaxmizeAcItemOpen] = useState(false);
  const [dtItemOpen, setDTItemOpen] = useState(false);
  const [maxmizeDtItemOpen, setMaxmizeDtItemOpen] = useState(false);
  const [issuesItemOpen, setIssuesItemOpen] = useState(false);
  const [participantsItemOpen, setParticipantsItemOpen] = useState(false);
  const [participants, setParticipants] = useState(false);
  const [maxmizeIssuesItemOpen, setMaxmizeIssuesItemOpen] = useState(false);
  const [allItemOpen, setAllItemOpen] = useState(true);
  const [RSItemOpen, setRSItemOpen] = useState(true);
  const [leftElementLabel, setLeftElementLabel] = useState("Notes");
  const [leftElementAction, setLeftElementAction] = useState("note");
  const [leftElementContent, setLeftElementContent] = useState(notesContent);
  const sec = 3000;
  const excludeImageAction = (actions) => {
    // Remove the image action from the toolbar
    return actions.filter((action) => action.name !== "image");
  };

  useEffect(() => {
    setLeftElementContent(notesContent);
  }, [notesContent]);

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(
        "https://docs.google.com/document/d/1vliqjd6M3hEx3vt90z39LPo0Zxe-1ThEjqjBMYe_FOc/edit"
      );
    }
  }, [isURLAdded]);
  const handleCloseNotes = () => {
    window.location.href = "/listofnotes";
    localStorage.removeItem("note_id");
  };

  const handleLeftsideOnChange = (content) => {
    setLeftElementOnChange(true);
    setLeftElementContent(content);
  };

  const handleOnChange = (content) => {
    setNotesOnChange(true);
    setNotesContent(content);
  };

  const handlePLOnChange = (content) => {
    setPLOnChange(true);
    setPLContent(content);
  };

  const handleAcIOnChange = (content) => {
    setAcIOnChange(true);
    setAcIContent(content);
  };

  const handleAgendaOnChange = (content) => {
    setAgendaOnChange(true);
    setAgendaContent(content);
  };
  const handleIssueOnChange = (content) => {
    setIssuesOnChange(true);
    setIssuesContent(content);
  };
  const handleDTOnChange = (content) => {
    setDTOnChange(true);
    setDTContent(content);
  };
  useEffect(() => {
    dispatch(
      getNotes(localStorage.getItem("note_id"), localStorage.getItem("bi_id"))
    );
  }, []);

  useEffect(() => {
    if (isNotesDataFetched) {
      if (getNotesData.meeting_dt) {
        dispatch({
          type: "SET_MEETING_DATA",
          payload: getNotesData.meeting_dt.map((obj, i) => {
            if (obj.notes_id === localStorage.getItem("note_id")) {
              dispatch({
                type: "SET_SELECTED_MEETING",
                payload: {
                  value: obj.notes_id,
                  label: obj.meeting,
                },
              });
            }
            return {
              value: obj.notes_id,
              label: obj.meeting,
            };
          }),
        });
      }
      if (getNotesData.list_of_notes) {
        const notes = getNotesData.list_of_notes;
        console.log(notes);
        const updatedOptions = notes.filter(
          (option) => option.notes_id === localStorage.getItem("note_id")
        );
        setNotesName(updatedOptions[0] && updatedOptions[0].title);
      }
      if (getNotesData.data) {
        getNotesData.data.map((obj) => {
          if (obj.action === "note") {
            setNotesContent(obj.content);
          } else if (obj.action === "parking_lot") {
            setPLContent(obj.content);
          } else if (obj.action === "action_item") {
            setAcIContent(obj.content);
          } else if (obj.action === "decision_taken") {
            setDTContent(obj.content);
          } else if (obj.action === "issue") {
            setIssuesContent(obj.content);
          } else if (obj.action === "agenda") {
            setAgendaContent(obj.content);
          } else if (obj.action === "drawing_board") {
            setDrBContent(obj.content);
          } else if (obj.action === "Participants List") {
            setParticipants(obj.value);
          }
        });
      }
    }
  }, [isGetActionChanged, isNotesDataFetched]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (leftElementOnChange && leftElementContent) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            leftElementContent,
            leftElementAction
          )
        );
        setLeftElementOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [leftElementOnChange, leftElementContent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notesName && notesNameOnChange) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            "",
            ""
          )
        );
      }
      setNotesNameOnChange(false);
    }, sec);
    return () => clearInterval(interval);
  }, [notesNameOnChange, notesName]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notesOnChange && notesContent) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            notesContent,
            "note"
          )
        );
        setNotesOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [notesOnChange, notesContent]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (PLOnChange && PLContent && PLContent !== "") {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            PLContent.replace('src="http://localhost', 'src="'),
            "parking_lot"
          )
        );
        setPLOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [PLOnChange, PLContent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (AcIOnChange && AcIContent) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            AcIContent.replace('src="http://localhost', 'src="'),
            "action_item"
          )
        );
        setAcIOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [AcIOnChange, AcIContent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (agendaOnChange && agendaContent) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            agendaContent,
            "agenda"
          )
        );
        setAgendaOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [agendaOnChange, agendaContent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dtOnChange && dtContent) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            dtContent,
            "decision_taken"
          )
        );
        setDTOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [dtOnChange, dtContent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (issuesOnChange && issuesContent) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            issuesContent,
            "issue"
          )
        );
        setIssuesOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [issuesOnChange, issuesContent]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (DrBOnChange && DrBContent && DrBContent?.length !== 0) {
        dispatch(
          postNotes(
            notesName,
            localStorage.getItem("bi_id"),
            localStorage.getItem("note_id"),
            DrBContent,
            "drawing_board"
          )
        );
        setDrBOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [DrBOnChange, DrBContent]);

  const handleNotesOnClick = () => {
    setAction("note");
    setActionChange(!actionChange);
    // dispatch(getNotes(localStorage.getItem("bi_id"), actionChange));
  };
  const handleDrBOnClick = () => {
    setAction("drawing_board");
    // setDrBContent(DrBContent ? DrBContent : []);
    setActionChange(!actionChange);
    // dispatch(getNotes(localStorage.getItem("bi_id"), actionChange));
  };

  const handleDrOnChange = useCallback((elements, state) => {
    console.log(state);
    if (
      elements.length > 0 &&
      JSON.stringify(elements) !== JSON.stringify(DrBContent)
    ) {
      setDrBContent((prevData) => {
        const copy = { ...state };
        delete copy["collaborators"];
        console.log(copy);
        return { elements: elements, appState: copy };
      });

      setDrBOnChange(true);
    }
  }, []);

  const maximizeHandler = (label, content) => {
    if (label === "Notes") {
      setMaxmizeNotesItem(true);
      setMaxmizeAgendaItem(false);
      setMaxmizePLItemOpen(false);
      setMaxmizeDtItemOpen(false);
      setMaxmizeIssuesItemOpen(false);
      setMaxmizeAcItemOpen(false);
      setLeftElementAction("note");
    } else if (label === "Agenda") {
      setMaxmizeAgendaItem(true);
      setMaxmizeNotesItem(false);
      setMaxmizePLItemOpen(false);
      setMaxmizeDtItemOpen(false);
      setMaxmizeIssuesItemOpen(false);
      setMaxmizeAcItemOpen(false);
      setLeftElementAction("agenda");
    } else if (label === "Parking Lot") {
      setMaxmizeNotesItem(false);
      setMaxmizeAgendaItem(false);
      setMaxmizePLItemOpen(true);
      setMaxmizeDtItemOpen(false);
      setMaxmizeIssuesItemOpen(false);
      setMaxmizeAcItemOpen(false);
      setLeftElementAction("parking_lot");
    } else if (label === "Action Items") {
      setMaxmizeAcItemOpen(true);
      setMaxmizeNotesItem(false);
      setMaxmizeAgendaItem(false);
      setMaxmizePLItemOpen(false);
      setMaxmizeDtItemOpen(false);
      setMaxmizeIssuesItemOpen(false);
      setLeftElementAction("action_item");
    } else if (label === "Decisions Taken") {
      setMaxmizeNotesItem(false);
      setMaxmizeAgendaItem(false);
      setMaxmizePLItemOpen(false);
      setMaxmizeDtItemOpen(true);
      setMaxmizeAcItemOpen(false);
      setMaxmizeIssuesItemOpen(false);
      setLeftElementAction("decision_taken");
    } else if (label === "Issues") {
      setMaxmizeNotesItem(false);
      setMaxmizeAgendaItem(false);
      setMaxmizePLItemOpen(false);
      setMaxmizeDtItemOpen(false);
      setMaxmizeAcItemOpen(false);
      setMaxmizeIssuesItemOpen(true);
      setLeftElementAction("issue");
    }
    setLeftElementLabel(label);
    setLeftElementContent(content);
  };

  const handleExpandView = (action) => {
    const url = `/meeting/${action}/`;
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };
  const handleNotesNameOnChange = (e) => {
    setNotesName(e.target.value);
    setNotesNameOnChange(true);
  };

  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      <div className="tab row" style={{ marginLeft: 5, marginRight: 5 }}>
        <div className="col-7">
          {/* <input
            type="text"
            style={{ width: "100%", border: "none", padding: "2px" }}
            value={notesName}
            onchange = {(e)=>setNotesName(e.target.value)}
          /> */}
          <Form.Control
            type="text"
            value={notesName}
            style={{ border: "none" }}
            // onChange={(e) => setNotesName(e.target.value)}
            onChange={(e) => handleNotesNameOnChange(e)}
          />
        </div>
        <button
          // style={{marginLeft:'25px'}}
          className={
            action === "note" ? "tablinks col-2 active" : "tablinks col-2"
          }
          onClick={(e) => handleNotesOnClick()}
        >
          <di
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            Notes
          </di>
        </button>
        <button
          className={
            action === "drawing_board"
              ? "tablinks col-2 active"
              : "tablinks col-2"
          }
          onClick={(e) => handleDrBOnClick()}
          style={{ borderRight: "1px solid gray" }}
        >
          <b
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            Drawing Board
          </b>
        </button>
        <button
          className="btn btn-sm tablinks col-1"
          onClick={handleCloseNotes}
          style={{ border: "none" }}
        >
          <b
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            Close
          </b>
        </button>
      </div>

      <div style={{ marginTop: 20, height: "calc(100% - 95px)" }}>
        {action !== "drawing_board" ? (
          <div style={{ display: "flex", height: "600px" }}>
            <div
              // className="left-element"
              style={{
                // marginTop: 25,
                width: RSItemOpen ? "60%" : "100%",
                height: "calc(100% - 25px)",
              }}
            >
              <label
                // className="left-element-label"
                style={{
                  textAlign: "center",
                  width: "100%",
                  fontSize: "var(--main-heading)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                {leftElementLabel}
                <div
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={(e) => setRSItemOpen(!RSItemOpen)}
                >
                  {RSItemOpen ? (
                    <AiOutlineCaretRight />
                  ) : (
                    <AiOutlineCaretLeft />
                  )}
                </div>
                <span
                  title="Open in New Tab"
                  style={{
                    float: "right",
                    cursor: "pointer",
                    paddingRight: "5px",
                  }}
                  onClick={(e) => handleExpandView(leftElementAction)}
                >
                  <img
                    alt=""
                    src={TabIcon}
                    style={{ width: "15px", height: "15px" }}
                  />
                </span>
              </label>
              <div style={{ height: "600px" }}>
                <QuillEditor
                  value={leftElementContent}
                  onChange={handleLeftsideOnChange}
                  height={"600px"}
                />
              </div>
            </div>
            {RSItemOpen && (
              <div
                className={"notes-rs-items"}
                style={{
                  height: "100%",
                  width: "40%",
                  marginLeft: 5,
                  overflow: "scroll",
                  paddingRight: 5,
                }}
              >
                {!maxmizeNotesItem && (
                  <div
                    onClick={(e) => setNotesItemOpen(!notesItemOpen)}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      border: notesItemOpen ? "" : "1px solid #ccc",
                      borderRadius: notesItemOpen ? "" : 10,
                      marginBottom: notesItemOpen ? "" : 5,
                      cursor: "pointer",
                    }}
                  >
                    Notes
                    <span
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingLeft: "5px",
                      }}
                    >
                      {notesItemOpen ? (
                        <AiOutlineCaretUp />
                      ) : (
                        <AiOutlineCaretDown />
                      )}
                    </span>
                    {/* <span
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={() => maximizeHandler("Notes", notesContent)}
                    >
                      <FaWindowMaximize style={{ fontSize: "small" }} />
                    </span> */}
                    <span
                      title="Open in New Tab"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={(e) => handleExpandView("note")}
                    >
                      <img
                        alt=""
                        src={TabIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </span>
                  </div>
                )}
                {notesItemOpen && !maxmizeNotesItem && (
                  <div style={{ height: "600px" }}>
                    <QuillEditor
                      value={notesContent}
                      onChange={handleOnChange}
                      height={"600px"}
                    />
                  </div>
                )}
                {!maxmizeAgendaItem && (
                  <div
                    onClick={(e) => setAgendaItemOpen(!agendaItemOpen)}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      border: agendaItemOpen ? "" : "1px solid #ccc",
                      borderRadius: agendaItemOpen ? "" : 10,
                      marginBottom: agendaItemOpen ? "" : 5,
                      cursor: "pointer",
                    }}
                  >
                    Agenda
                    <span
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingLeft: "5px",
                      }}
                    >
                      {agendaItemOpen ? (
                        <AiOutlineCaretUp />
                      ) : (
                        <AiOutlineCaretDown />
                      )}
                    </span>
                    {/* <span
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={() => maximizeHandler("Agenda", agendaContent)}
                    >
                      <FaWindowMaximize style={{ fontSize: "small" }} />
                    </span> */}
                    <span
                      title="Open in New Tab"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={(e) => handleExpandView("agenda")}
                    >
                      <img
                        alt=""
                        src={TabIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </span>
                  </div>
                )}
                {agendaItemOpen && !maxmizeAgendaItem && (
                  <div>
                    <QuillEditor
                      value={agendaContent}
                      onChange={handleAgendaOnChange}
                    />
                  </div>
                )}
                {!maxmizePLItemOpen && (
                  <div
                    onClick={(e) => setPLItemOpen(!PLItemOpen)}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      border: PLItemOpen ? "" : "1px solid #ccc",
                      borderRadius: PLItemOpen ? "" : 10,
                      marginBottom: PLItemOpen ? "" : 5,
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                  >
                    Parking Lot
                    <span style={{ float: "right" }}>
                      {PLItemOpen ? (
                        <AiOutlineCaretUp />
                      ) : (
                        <AiOutlineCaretDown />
                      )}
                    </span>
                    {/* <span
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={() => maximizeHandler("Parking Lot", PLContent)}
                    >
                      <FaWindowMaximize style={{ fontSize: "small" }} />
                    </span> */}
                    <span
                      title="Open in New Tab"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={(e) => handleExpandView("parking_lot")}
                    >
                      <img
                        alt=""
                        src={TabIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </span>
                  </div>
                )}
                {PLItemOpen && !maxmizePLItemOpen && (
                  <div>
                    <QuillEditor
                      value={PLContent}
                      onChange={handlePLOnChange}
                    />
                  </div>
                )}
                {!maxmizeAcItemOpen && (
                  <div
                    onClick={(e) => setAcItemOpen(!acItemOpen)}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      border: acItemOpen ? "" : "1px solid #ccc",
                      borderRadius: acItemOpen ? "" : 10,
                      marginBottom: acItemOpen ? "" : 5,
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                  >
                    Action Items
                    <span style={{ float: "right" }}>
                      {acItemOpen ? (
                        <AiOutlineCaretUp />
                      ) : (
                        <AiOutlineCaretDown />
                      )}
                    </span>
                    {/* <span
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={() =>
                        maximizeHandler("Action Items", AcIContent)
                      }
                    >
                      {!maxmizeAcItemOpen && (
                        <FaWindowMaximize style={{ fontSize: "small" }} />
                      )}
                    </span> */}
                    <span
                      title="Open in New Tab"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={(e) => handleExpandView("action_item")}
                    >
                      <img
                        alt=""
                        src={TabIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </span>
                  </div>
                )}
                {acItemOpen && !maxmizeAcItemOpen && (
                  <div>
                    <QuillEditor
                      value={AcIContent}
                      onChange={handleAcIOnChange}
                    />
                  </div>
                )}
                {!maxmizeDtItemOpen && (
                  <div
                    onClick={(e) => setDTItemOpen(!dtItemOpen)}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      border: dtItemOpen ? "" : "1px solid #ccc",
                      borderRadius: dtItemOpen ? "" : 10,
                      marginBottom: dtItemOpen ? "" : 5,
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                  >
                    Decisions Taken
                    <span style={{ float: "right" }}>
                      {dtItemOpen ? (
                        <AiOutlineCaretUp />
                      ) : (
                        <AiOutlineCaretDown />
                      )}
                    </span>
                    {/* <span
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={() =>
                        maximizeHandler("Decisions Taken", dtContent)
                      }
                    >
                      {!maxmizeDtItemOpen && (
                        <FaWindowMaximize style={{ fontSize: "small" }} />
                      )}
                    </span> */}
                    <span
                      title="Open in New Tab"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={(e) => handleExpandView("decision_taken")}
                    >
                      <img
                        alt=""
                        src={TabIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </span>
                  </div>
                )}
                {dtItemOpen && !maxmizeDtItemOpen && (
                  <div>
                    <QuillEditor
                      value={dtContent}
                      onChange={handleDTOnChange}
                    />
                  </div>
                )}
                {!maxmizeIssuesItemOpen && (
                  <div
                    onClick={(e) => setIssuesItemOpen(!issuesItemOpen)}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      border: issuesItemOpen ? "" : "1px solid #ccc",
                      borderRadius: issuesItemOpen ? "" : 10,
                      marginBottom: issuesItemOpen ? "" : 5,
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                  >
                    Issues
                    <span style={{ float: "right" }}>
                      {issuesItemOpen ? (
                        <AiOutlineCaretUp />
                      ) : (
                        <AiOutlineCaretDown />
                      )}
                    </span>
                    {/* <span
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={() => maximizeHandler("Issues", issuesContent)}
                    >
                      {!maxmizeIssuesItemOpen && (
                        <FaWindowMaximize style={{ fontSize: "small" }} />
                      )}
                    </span> */}
                    <span
                      title="Open in New Tab"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        paddingRight: "5px",
                      }}
                      onClick={(e) => handleExpandView("issue")}
                    >
                      <img
                        alt=""
                        src={TabIcon}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </span>
                  </div>
                )}
                {issuesItemOpen && !maxmizeIssuesItemOpen && (
                  <div>
                    <QuillEditor
                      value={issuesContent}
                      onChange={handleIssueOnChange}
                    />
                  </div>
                )}
                <div
                  onClick={(e) =>
                    setParticipantsItemOpen(!participantsItemOpen)
                  }
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                    border: participantsItemOpen ? "" : "1px solid #ccc",
                    borderRadius: participantsItemOpen ? "" : 10,
                    marginBottom: participantsItemOpen ? "" : 5,
                    cursor: "pointer",
                    marginTop: 5,
                  }}
                >
                  Participants
                  <span style={{ float: "right" }}>
                    {participantsItemOpen ? (
                      <AiOutlineCaretUp />
                    ) : (
                      <AiOutlineCaretDown />
                    )}
                  </span>
                  {/* <span
                    title="Open in New Tab"
                    style={{
                      float: "right",
                      cursor: "pointer",
                      paddingRight: "5px",
                    }}
                  >
                    <img
                      alt=""
                      src={TabIcon}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </span> */}
                </div>
                {participantsItemOpen && (
                  <div style={{ height: "45.5%" }}></div>
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            {/* <iframe height="100%" width="100%" src="https://excalidraw.com/" /> */}
            <span
              title="Open in New Tab"
              style={{
                float: "right",
                cursor: "pointer",
                paddingRight: "5px",
              }}
              onClick={(e) => handleExpandView("drawing_board")}
            >
              <img
                alt=""
                src={TabIcon}
                style={{ width: "15px", height: "15px" }}
              />
            </span>
            <div style={{ width: "100%", height: "calc(80vh - 95px)" }}>
              <Excalidraw
                initialData={DrBContent}
                onChange={handleDrOnChange}
              />
            </div>
          </>
        )}
      </div>
    </motion.div>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={false}
        // breadcrumbs={
        //   "Meeting - " +
        //   new Date().toLocaleString("en-US", { day: "2-digit" }) +
        //   "-" +
        //   new Date().toLocaleString("en-US", { month: "2-digit" }) +
        //   "-" +
        //   new Date().getFullYear()
        // }
        showMenu={true}
        showScreen={"notes"}
        showFullScreen={true}
        isMenuCollapse={true}
      />
    );
  }
}

export default Notes;
