import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Add from "../../assets/images/add.svg";
import { getRandomColor, createImageFromInitials } from "../Utils";
import { useSelector, useDispatch } from "react-redux";
import { postFunGoalKeyRiskIssues } from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { resetExceptionError } from "../../store/actions/index.js";
import Alert from "../Alert.js";
import { motion } from "motion/react";

const RiskAndIssuesPopUp = ({
  ratingData,
  isEdit,
  setIsEdit,
  empData,
  isTaskSidebarOpen,
  setIsTaskSidebarOpen,
  setRiskAndIssuesDisplay,
  sidebarData,
  setRiskIssuesData,
  riskIssuesData,
}) => {
  const dispatch = useDispatch();
  const ISError = useSelector((state) => state.ISError);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [rating, setRating] = useState(null);
  const [selectedId, setSelectedId] = useState(false);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [formData, setFormData] = useState({
    title: "",
    desc: "",
    response: "",
    date: "",
    rating: "",
    assigned_to: "",
  });

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  useEffect(() => {
    if (sidebarData && isEdit) {
      setSelectedOption(
        sidebarData.assigned_to.filter((employee) => employee.is_active)
      );
      setSelectedId(sidebarData.key);
      setRating({ label: sidebarData.rating, value: sidebarData.rating });
      setFormData({
        title: sidebarData.title || "",
        desc: sidebarData.value || "",
        response: sidebarData.mitigation || "",
        date: sidebarData.date_time || "",
        rating: { label: sidebarData.rating, value: sidebarData.rating },
        assigned_to: sidebarData.assigned_to,
      });
    }
  }, [sidebarData]);

  const handleOwnerChange = (selectedOptions) => {
    const updatedOptions = selectedOptions.filter((option) =>
      empData.some((emp) => emp.value === option.value)
    );
    setSelectedOption(updatedOptions);
    const updatedOptionsWithStructure = updatedOptions.map((option) => ({
      key: option.value,
      is_active: true,
    }));
    setFormData({
      ...formData,
      assigned_to: updatedOptionsWithStructure,
    });
  };
  const handleRatingSelect = (selectedOption) => {
    setRating(selectedOption);
    setFormData({
      ...formData,
      rating: selectedOption.value,
    });
  };

  const handleSubmit = () => {
    if (formData.title !== "") {
      const selectedOwnerWithActive = empData.map((sowner) => ({
        key: sowner.value,
        is_active:
          selectedOption &&
          selectedOption.some(
            (selectedOwner) => selectedOwner.value === sowner.value
          ),
      }));
      if (isEdit) {
        setIsTaskSidebarOpen(false);
        setIsEdit(false);
        setRiskAndIssuesDisplay(false);
        dispatch(
          postFunGoalKeyRiskIssues(
            false,
            false,
            formData.title,
            formData.desc,
            formData.date,
            formData.rating,
            formData.response,
            "edit",
            selectedOwnerWithActive,
            selectedId
          )
        );
      } else {
        dispatch(
          postFunGoalKeyRiskIssues(
            localStorage.getItem("functional_goal_id"),
            localStorage.getItem("goal_review_id"),
            formData.title,
            formData.desc,
            formData.date,
            formData.rating,
            formData.response,
            "add",
            selectedOwnerWithActive
          )
        );
        setIsTaskSidebarOpen(false);
        setRiskAndIssuesDisplay(false);
      }
    } else {
      Alert.warning({
        title: "Warning",
        text: "Title should not be empty",
        html: ISError,
        icon: "warning",
        preConfirm: (isConfirm) => {
          if (isConfirm) {
            dispatch(resetExceptionError());
          } else {
          }
        },
      });
    }
  };

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "flex",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };
  const handleFullScreen = () => {
    setFullScreenMode(true);
  };
  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeTaskNav = () => {
    setIsTaskSidebarOpen(false);
    setRiskAndIssuesDisplay(false);
    setIsEdit(false);
  };
  const handleSideNav = () => {
    setFullScreenMode(false);
  };
  const handleDelete = () => {
    if (isEdit) {
      const updatedItems = riskIssuesData.filter(
        (item) => item.key !== selectedId
      );
      setRiskIssuesData(updatedItems);
    }
    dispatch(
      postFunGoalKeyRiskIssues(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        "delete",
        false,
        selectedId
      )
    );
    setIsTaskSidebarOpen(false);
    setMenuOpen(false);
  };

  return (
    <div>
      {isTaskSidebarOpen && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 1 }}
          exit={{ x: "100%" }}
          style={{
            // width: fullScreenMode ? "85%" : "50%",
            width: fullScreenMode && "calc(100% - 50px)",
            zIndex: "100",
          }}
          className={"sidenav"}
        >
          <div
            // style={{
            //   width: fullScreenMode ? "88.1%" : "50%",
            // }}
            className={"gtm-sidebar-header"}
          >
            <h5
              style={{
                marginLeft: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Risk & Issues
            </h5>
            {!fullScreenMode ? (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                {isMenuOpen && (
                  <div className="menu">
                    <div className="Edit">
                      <div
                        className="DeleteOption"
                        onClick={(e) => handleDelete()}
                      >
                        <img
                          src={DeleteIcon}
                          width={"18px"}
                          style={{ marginRight: "10px" }}
                          alt="Edit Icon"
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                )}
                <a href="#" className="fullscreen" onClick={handleFullScreen}>
                  <AiOutlineCaretRight />
                </a>
              </>
            ) : (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                <a href="#" className="fullscreen" onClick={handleSideNav}>
                  <AiOutlineCaretLeft />
                </a>
              </>
            )}
            <a href="#" className="closebtn" onClick={() => closeTaskNav()}>
              &times;
            </a>
          </div>
          <div className="gtm-sidebar-body">
            <input
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              style={{
                width: "98%",
                margin: "auto",
              }}
              className="form-control"
              value={formData.title}
              placeholder="Add your title"
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
            <br />
            <div
              className="row"
              style={{ marginBottom: "10px", justifyContent: "space-between" }}
            >
              <div
                className="col-6"
                style={{
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    margin: "0px 0px 0px 10px",
                    width: "fit-content",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Tgt. Closure
                </h6>
                <input
                  disabled={
                    selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin"
                      ? false
                      : true
                  }
                  style={{
                    marginLeft: "10px",
                    width: "100%",
                    fontSize: "var(--text-font-size)",
                  }}
                  type="date"
                  className="form-control"
                  value={formData.date}
                  onChange={(e) => handleInputChange("date", e.target.value)}
                />
              </div>
              <div
                className="col-6"
                style={{
                  alignItems: "center",
                  marginBottom: "20px",
                  zIndex: 999,
                }}
              >
                <h6
                  style={{
                    marginBottom: 0,
                    marginRight: "10px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Rating
                </h6>
                <Select
                  isDisabled={
                    selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin"
                      ? false
                      : true
                  }
                  placeholder={
                    <span
                      style={{
                        fontSize: "var(--text-font-size)",
                        fontWeight: "400",
                      }}
                    >
                      Select Rating
                    </span>
                  }
                  required
                  styles={customStyles}
                  className="status-select"
                  value={rating}
                  options={ratingData}
                  onChange={handleRatingSelect}
                  isSearchable={true}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "var(--text-font-size)",
                          fontWeight: "400",
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  )}
                />
              </div>
              <div
                className="col-6"
                style={{
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <h6
                  style={{
                    margin: "0px 0px 0px 10px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Owner
                </h6>
                <div style={{ paddingLeft: "10px" }}>
                  <Select
                    isDisabled={
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? false
                        : true
                    }
                    required
                    name="emp_id"
                    isMulti
                    hideSelectedOptions
                    allowSelectAll
                    styles={customStyles}
                    placeholder={
                      <span
                        style={{
                          fontSize: "var(--text-font-size)",
                          fontWeight: "400",
                        }}
                      >
                        Select Owner
                      </span>
                    }
                    value={selectedOption}
                    options={empData}
                    onChange={handleOwnerChange}
                    isSearchable={true}
                    filterOption={customFilter}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={e.icon} />
                        <span
                          style={{
                            marginLeft: 5,
                            fontSize: "var(--text-font-size)",
                            fontWeight: "400",
                          }}
                        >
                          {e.label}
                        </span>
                      </div>
                    )}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      // Option: EmpIconOption,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                margin: "0px 0px 0px 10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Description
            </div>
            <textarea
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              style={{
                width: "98%",
                minHeight: "20vh",
                margin: "auto",
                fontSize: "var(--sub-heading-font-size)",
              }}
              className="form-control"
              value={formData.desc}
              placeholder="Add your description"
              onChange={(e) => handleInputChange("desc", e.target.value)}
            />
            <br />
            <div
              style={{
                margin: "0px 0px 0px 10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Mitigation/Response
            </div>
            <textarea
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              style={{
                width: "98%",
                minHeight: "20vh",
                margin: "auto",
                fontSize: "var(--sub-heading-font-size)",
              }}
              className="form-control"
              value={formData.response}
              placeholder="Add your response"
              onChange={(e) => handleInputChange("response", e.target.value)}
            />
          </div>
          <br />
          <div
            className="strategy-sidebar-footer"
            style={{
              display: "flex",
              justifyContent: "right",
              width: "96%",
            }}
          >
            <div
              className="col-1"
              style={{
                display: "flex",
                marginTop: "2px",
              }}
            >
              <Button
                type="button"
                style={{
                  height: "35px",
                  position: "absolute",
                  top: "8px",
                  right: "150px",
                  padding: "4px 10px",
                  backgroundColor: "var(--button-primary-color)",
                  fontSize: "var(--sub-heading-font-size)",
                  border: "1px solid var(--button-primary-color)",
                }}
                disabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default RiskAndIssuesPopUp;
