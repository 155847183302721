import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
import Example from "../MetricsCard";
import Image from "../../assets/images/MetricsModalicon.svg";
import "./Metrics.css";
import Select from "react-select";
import { getMetrics, postMetrics } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/trash.svg";
import HelpInfo from "../../utils/helpInfoProviderComponent";
import keys from "../../utils/helpInfoKeys.json";
import CommentSection from "../../utils/CommentSection";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";
import { motion } from "motion/react";

function Metrics() {
  const [showPopup, setShowPopup] = useState(false);
  const template_url = useSelector((state) => state.template_url);
  const [currentDocURL, setCurrentDocURL] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [option, setOption] = useState("");
  const [typeData, setTypeData] = useState([]);
  const isURLAdded = useSelector((state) => state.isURLAdded);
  const [isMetricsSave, setIsMetricsSave] = useState(false);
  const [isMetricsUpdated, setIsMetricsUpdated] = useState(false);
  const isMetricsSaved = useSelector((state) => state.isMetricsSaved);
  const isMetricsPosted = useSelector((state) => state.isMetricsPosted);
  const postedMetricData = useSelector((state) => state.postedMetricData);

  const isMetricUpdateFetched = useSelector(
    (state) => state.isMetricUpdateFetched
  );
  const isMetricsFetched = useSelector((state) => state.isMetricsFetched);
  const getMetricData = useSelector((state) => state.getMetricData);
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [cus_items, setCusItems] = useState([]);
  const [metric_id, setMetricID] = useState("");
  const [inputAlert, setInputAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilteredData, setSearchFilteredData] = useState(filteredData);
  const [selectedItems, setSelectedItems] = useState(
    cus_items.map((item) => item.key)
  );
  const [selectedCheckboxData, setSelectedCheckBoxData] = useState([]);
  const [selectAddedItems, setSelectAddedItems] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedType, setSelectedType] = useState(null);
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // This will run every time the searchQuery changes
    if (searchQuery === "") {
      setSearchFilteredData(filteredData);
    } else {
      setSearchFilteredData(
        filteredData.filter((item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    dispatch(
      getMetrics(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);
  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);
  const closeModal = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    setSelectedItems(cus_items.map((item) => item.key));
  }, [cus_items]);

  const handleAddMetricsPopup = () => {
    setName("");
    setDesc("");
    setOption("");
    setShowPopup(true);
  };

  useEffect(() => {
    if (isMetricsFetched) {
      if (getMetricData) {
        setTypeData(
          getMetricData.mst_type.map((obj) => {
            return { label: obj.metric_value_type, value: obj.name };
          })
        );
        setItems(
          getMetricData.mst_data.map((obj) => {
            return {
              title: obj.label,
              description: obj.desc,
              isselected: obj.isactive,
              key: obj.value,
              is_master: obj.is_master,
              type: obj.type,
              isactive: obj.isactive,
            };
          })
        );
        setCusItems(
          getMetricData.cus_data.map((obj) => {
            return {
              title: obj.label,
              description: obj.desc,
              key: obj.value,
              is_master: obj.is_master,
              type: obj.type,
            };
          })
        );
      }
    }
  }, [isMetricsFetched, getMetricData]);
  useEffect(() => {
    if (isMetricsPosted) {
      if (postedMetricData && postedMetricData.status === 200) {
        setMetricID("");
        setIsMetricsUpdated(!isMetricsUpdated);
        dispatch(
          getMetrics(
            localStorage.getItem("bi_id"),
            localStorage.getItem("product_id"),
            isMetricsUpdated
          )
        );
      }
    }
  }, [postedMetricData, isMetricsPosted]);

  useEffect(() => {
    if (typeData && typeData[0]) {
      const metric_category = localStorage.getItem("metric_category");
      if (!metric_category) {
        localStorage.setItem("metric_category", typeData[0].value);
        const filteredItems = items.filter(
          (item) => item.type === typeData[0].value
        );
        setFilteredData(filteredItems);
        setSearchFilteredData(filteredItems);
        setSelectedType(typeData[0].value);
      } else {
        const filteredItems = items.filter(
          (item) => item.type === metric_category
        );
        setFilteredData(filteredItems);
        setSearchFilteredData(filteredItems);
      }
    }
  }, [typeData]);
  const handleClose = () => {
    setShowPopup(false);
    setInputAlert(false);
    setTypeAlert(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    setInputAlert(false);
  };
  const handleMetricSave = (e) => {
    if (name !== "" && option !== "") {
      const create_data = [
        { id: metric_id, name: name, desc: desc, value_type: option.value },
      ];
      setMetricID("");
      setIsMetricsSave(!isMetricsSave);
      dispatch(
        postMetrics(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          create_data,
          null,
          null,
          isMetricsSave
        )
      );
      setShowPopup(false);
      const updated_data = {
        key: metric_id,
        title: name,
        description: desc,
        type: option.value,
        is_master: false,
        isactive: true,
      };
      const existingItemIndex = filteredData.findIndex(
        (item) => item.key === metric_id
      );

      if (existingItemIndex !== -1) {
        // If key exists, update the existing item
        setFilteredData((prevData) => {
          const newData = [...prevData];
          newData[existingItemIndex] = updated_data;
          return newData;
        });
      } else {
        setFilteredData((prevData) => [...prevData, updated_data]);
      }
    } else {
      if (name === "") {
        setInputAlert(true);
      } else {
        setTypeAlert(true);
      }
    }
  };
  const handleSelectOption = (e) => {
    setOption(e);
    setTypeAlert(false);
  };
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const customFilter1 = (option, searchText) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.desc.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
    menu: (provided, state) => ({ ...provided, zIndex: 100 }),
  };
  const handleMetricsEdit = (id) => {
    const data = filteredData.find((item) => item.key === id);
    setMetricID(id);
    setName(data.title);
    setDesc(data.description);
    typeData.map((obj) => {
      if (obj.value === data.type) {
        setOption(obj);
      }
    });
    setShowPopup(true);
  };
  const handleMetricsDelete = (id) => {
    setFilteredData((prevData) => prevData.filter((item) => item.key !== id));
    dispatch(
      postMetrics(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        null,
        null,
        id,
        true
      )
    );
  };
  const disabledKeys = new Set(cus_items.map((item) => item.key));

  const handleBack = () => {
    navigate("/operatingplan");
    window.location.reload();
  };
  const handleClick = () => {
    navigate("/dashboard");
  };

  const handleMetricClick = (itemValue) => {
    const filteredItems = items.filter((item) => item.type === itemValue);
    setFilteredData(filteredItems);
    setSearchFilteredData(filteredItems);
    setSelectedType(itemValue);
    localStorage.setItem("metric_category", itemValue);
  };
  const handleCheckboxChange = (key) => {
    const updatedSelection = selectedItems.includes(key)
      ? selectedItems.filter((itemKey) => itemKey !== key)
      : [...selectedItems, key];

    setSelectedItems(updatedSelection);
  };
  const handleSendButtonClick = () => {
    const selectedData = selectedItems.map((key) =>
      items.find((item) => item.key == key)
    );
    setSelectedCheckBoxData(selectedData);
    dispatch(
      postMetrics(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        null,
        selectedData,
        false,
        null,
        true
      )
    );
  };
  const handleAddedCheckboxChange = (key) => {
    setSelectAddedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(key)) {
        return prevSelectedItems.filter((item) => item !== key);
      } else {
        return [...prevSelectedItems, key];
      }
    });
  };
  const handleRemoveButtonClick = () => {
    const selectedData = cus_items.filter((item) =>
      selectAddedItems.includes(item.key)
    );
    const data = selectedCheckboxData.filter(
      (item) => !selectAddedItems.includes(item.key)
    );
    setCusItems(data);
    setSelectAddedItems([]);
    dispatch(
      postMetrics(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        null,
        selectedData,
        true,
        null,
        true
      )
    );
  };

  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      style={{ width: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Track the Progress on the initiative
        </div>
      </div>
      <div
        style={{ position: "absolute", top: "72px", zIndex: 1, right: "370px" }}
      >
        <input
          className="form-control"
          type="text"
          placeholder="Search metric in category"
          style={{
            width: 250,
          }}
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      {showPopup && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 1 }}
          exit={{ x: "100%" }}
          className={"sidenav"}
        >
          <div
            className="gtm-sidebar-header"
            style={{
              borderBottom: "1px solid lightgrey",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Metrics &gt; Create Metric
            </div>
            <a href="#" className="closebtn" onClick={closeModal}>
              &times;
            </a>
          </div>
          <div>
            <div style={{ padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  width: "98%",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6
                    style={{
                      marginRight: "20px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Name<span className="required">*</span>
                  </h6>
                  <input
                    className="form-control"
                    id="productname"
                    placeholder="Metric name"
                    value={name}
                    onChange={handleNameChange}
                    required
                    style={{
                      fontSize: "var(--text-font-size)",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    width: "49%",
                  }}
                >
                  <h6
                    style={{
                      marginRight: "20px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Category <span className="required">*</span>
                  </h6>
                  <div style={{ marginTop: "-10px" }}>
                    <Select
                      className="metric_category"
                      required
                      name="metric_type_id"
                      placeholder={
                        <span
                          style={{
                            fontSize: "var(--text-font-size)",
                          }}
                        >
                          Select category
                        </span>
                      }
                      value={option}
                      options={typeData}
                      onChange={handleSelectOption}
                      isSearchable={true}
                      filterOption={customFilter}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              marginLeft: 5,
                              fontSize: 16,
                              fontSize: "var(--sub-heading-font-size)",
                            }}
                          >
                            {e.label}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              {inputAlert && (
                <Alert
                  variant="danger"
                  style={{ fontSize: "var(--text-font-size)" }}
                >
                  Name should not be empty
                </Alert>
              )}
              <div style={{ marginTop: "5px" }}>
                {typeAlert && (
                  <Alert
                    variant="danger"
                    className="alert-danger"
                    style={{ fontSize: "var(--text-font-size)" }}
                  >
                    Category should not be empty
                  </Alert>
                )}
              </div>
              <h6
                style={{
                  marginRight: "20px",
                  marginLeft: "10px",
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Description
              </h6>
              <textarea
                style={{
                  width: "98%",
                  fontSize: "var(--text-font-size)",
                  margin: "auto",
                }}
                className="form-control"
                id="productname"
                placeholder="Describe the metric"
                value={desc}
                rows={10}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              height: "100px",
            }}
          >
            <Button
              type="button"
              className="save-button-style-rhpo"
              onClick={() => handleMetricSave()}
              disabled={
                selectedPermission === "write" || selectedPermission === "admin"
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </div>
        </motion.div>
      )}
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div className="metrics-outer-container">
          <div
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Category List
          </div>
          <div className="metrics-inner-container">
            {typeData.map((item, index) => (
              <div
                key={index}
                style={{
                  margin: "10px",
                  cursor: "pointer",
                  backgroundColor:
                    localStorage.getItem("metric_category") === item.value
                      ? "lightblue"
                      : "transparent",
                  borderRadius: "10px",
                  padding: "5px",
                }}
                onClick={() => handleMetricClick(item.value)}
              >
                <span
                  style={{
                    fontSize: "var(--text-font-size)",
                  }}
                >
                  {item.label}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="metrics-outer-container">
          <div
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Metrics List
          </div>
          <div className="metrics-inner-container">
            {searchFilteredData.map((item) => (
              <>
                <div
                  key={item.key}
                  style={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onMouseEnter={() => setHoveredIndex(item.key)}
                  onMouseLeave={() => setHoveredIndex(-1)}
                >
                  <div style={{ display: "flex" }}>
                    <input
                      style={{
                        cursor: "pointer",
                        fontSize: "var(--text-font-size)",
                      }}
                      type="checkbox"
                      checked={selectedItems.includes(item.key)}
                      onChange={() => handleCheckboxChange(item.key)}
                      id={item.key}
                      value={item.key}
                      disabled={disabledKeys.has(item.key)}
                    />
                    <label
                      htmlFor={item.key}
                      style={
                        item.is_master === false
                          ? {
                              color: "black",
                              paddingLeft: "5px",
                              cursor: "pointer",
                              fontSize: "var(--text-font-size)",
                            }
                          : {
                              color: "gray",
                              paddingLeft: "5px",
                              cursor: "pointer",
                              fontSize: "var(--text-font-size)",
                            }
                      }
                    >
                      {item.title}
                    </label>
                  </div>
                  {hoveredIndex === item.key && item.is_master === false && (
                    <div style={{ display: "flex", marginRight: "10px" }}>
                      <img
                        src={EditIcon}
                        width={"14px"}
                        alt="Edit Icon"
                        style={{ marginRight: "15px", cursor: "pointer" }}
                        onClick={() => handleMetricsEdit(item.key)}
                      />
                      <img
                        src={DeleteIcon}
                        width={"14px"}
                        alt="Delete Icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleMetricsDelete(item.key)}
                      />
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            onClick={handleSendButtonClick}
            type="button"
            disabled={
              selectedPermission === "write" || selectedPermission === "admin"
                ? false
                : true
            }
            style={{
              color: "#0A5F59",
              backgroundColor: "transparent",
              text: "center",
              border: "none",
              position: "static",
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            Add &gt;&gt;
          </Button>
          <Button
            onClick={handleRemoveButtonClick}
            type="button"
            disabled={selectedPermission === "admin" ? "" : "true"}
            style={{
              color: "#ef2c2c",
              backgroundColor: "transparent",
              width: "fit-content",
              border: "1px solid var(--rs-border-primary)",
              position: "static",
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            &lt;&lt; Remove
          </Button>
        </div>
        <div className="metrics-outer-container">
          <div
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Selected Metrics
          </div>

          <div className="metrics-inner-container">
            {cus_items.map((item, index) => (
              <div key={item.key} style={{ margin: "10px" }}>
                <input
                  style={{ cursor: "pointer" }}
                  type="checkbox"
                  checked={selectAddedItems.includes(item.key)}
                  onChange={() => handleAddedCheckboxChange(item.key)}
                  id={index}
                  value={item.key}
                />
                <label
                  htmlFor={index}
                  style={
                    item.is_master === false
                      ? {
                          color: "black",
                          paddingLeft: "5px",
                          cursor: "pointer",
                          fontSize: "var(--text-font-size)",
                        }
                      : {
                          color: "gray",
                          paddingLeft: "5px",
                          cursor: "pointer",
                          fontSize: "var(--text-font-size)",
                        }
                  }
                >
                  {item.title}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <CommentSection />
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
    </motion.div>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Strategy Validation"}
        breadcrumbChild={"Metrics"}
        documentName={"Metrics"}
        helpKey={keys["Metrics"].Name}
        image={"question"}
        handleAddMetricsPopup={handleAddMetricsPopup}
        showMetricButton={true}
        selectedPermission={selectedPermission}
      />
    );
  }
}

export default Metrics;
