import React, { useEffect, useState, useTransition } from "react";
import DeleteIcon from "../../../assets/images/trash.svg";
import EditIcon from "../../../assets/images/Edit.svg";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postEcosystemMappingSectionDetails } from "../../../store/actions";
import { motion } from "motion/react";

const EcMappingTasksidebar = ({
  onClose,
  handleEdit,
  editMode,
  setEditItemData,
  edititemData,
  setEditMode,
  seletedParentId,
  ChildrenData,
  childName,
  subSectionName,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(null);
  const [newItem, setNewItem] = useState("");
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };
  const handleMouseLeave = () => {
    setIsHovered(null);
  };
  const handleSaveOption = (seletedParentId) => {
    if (newItem.trim() !== "") {
      dispatch(
        postEcosystemMappingSectionDetails(
          localStorage.getItem("bi_id"),
          seletedParentId,
          newItem,
          true,
          "add"
        )
      );
      setNewItem("");
    }
  };
  const handleInputChange = (e) => {
    setNewItem(e.target.value);
  };
  const handleEditData = (e) => {
    setEditItemData(e);
  };
  const handleEditSave = (childrenId) => {
    setEditMode(false);
    dispatch(
      postEcosystemMappingSectionDetails(
        false,
        false,
        edititemData,
        true,
        "edit",
        childrenId
      )
    );
  };
  const handleDelete = (childrenId) => {
    dispatch(
      postEcosystemMappingSectionDetails(
        false,
        false,
        false,
        false,
        "delete",
        childrenId
      )
    );
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 1 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      className={"sidenav"}
    >
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <h6
          style={{
            flex: "1",
            minWidth: "70%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
            paddingTop: "4px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
            paddingLeft: "10px",
          }}
        >
          <span>{subSectionName} &gt; </span>
          {childName}
        </h6>
        <a href="#" className="closebtn" onClick={onClose}>
          &times;
        </a>
      </div>
      <div className="gtm-sidebar-body">
        {(selectedPermission === "write" || selectedPermission === "admin") && (
          <form
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveOption(seletedParentId);
            }}
          >
            <input
              className="form-control"
              type="text"
              value={newItem}
              onChange={handleInputChange}
              placeholder="Enter data"
              autoFocus
            />
            <Button
              style={{
                width: "fit-content",
                cursor: "pointer",
                marginLeft: "10px",
                backgroundColor: "#0A5F59",
                borderColor: "#0A5F59",
                padding: "4px 10px",
              }}
              type="submit"
            >
              Save
            </Button>
          </form>
        )}

        {/* <div className="ecmapping-ol">
          <ol>
            {ChildrenData.childrens.length > 0 ? (
              <>
                {ChildrenData.childrens.map((child, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    style={{ paddingBottom: "5px" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {editMode === index ? (
                        <>
                          <input
                            className="form-control"
                            style={{
                              width: "41%",
                            }}
                            type="text"
                            value={edititemData}
                            onChange={(e) => handleEditData(e.target.value)}
                            placeholder="Enter data"
                          />
                          <Button
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                              backgroundColor: "#0A5F59",
                              borderColor: "#0A5F59",
                            }}
                            onClick={(e) => {
                              handleEditSave(child.id);
                            }}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <span
                          style={{ fontSize: "var(--text-font-size: 16px)" }}
                        >
                          {child.name}
                        </span>
                      )}
                      {isHovered === index &&
                        editMode !== index &&
                        (selectedPermission === "write" ||
                          selectedPermission === "admin") && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {!child.is_master && (
                              <img
                                src={EditIcon}
                                width={"14px"}
                                alt="Edit Icon"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  handleEdit(index, child, ChildrenData.id);
                                }}
                              />
                            )}
                            {selectedPermission === "admin" && (
                              <img
                                src={DeleteIcon}
                                width={"14px"}
                                alt="Delete Icon"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={() => handleDelete(child.id)}
                              />
                            )}
                          </div>
                        )}
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <div style={{marginLeft:'-20px'}}>No Data Found.</div>
            )}
          </ol>
        </div> */}
        <div
          style={{ height: "80%", overflow: "auto", marginLeft: "0px 10px" }}
        >
          {ChildrenData && ChildrenData.childrens?.length > 0 ? (
            <table className="ca-table">
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px 16px",
                      fontSize: "var(--sub-heading-font-size)",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    No.
                  </th>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      fontSize: "var(--sub-heading-font-size)",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {childName}
                  </th>
                </tr>
              </thead>
              <tbody>
                {ChildrenData.childrens.map((child, index) => (
                  <tr
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td
                      style={{
                        width: "10%",
                        textAlign: "left",
                        paddingLeft: "10px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td style={{ width: "50%", padding: "0px 10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        {editMode === index ? (
                          <>
                            <form
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleEditSave(child.id);
                              }}
                            >
                              <input
                                style={{ width: "90%" }}
                                type="text"
                                value={edititemData}
                                onChange={(e) => handleEditData(e.target.value)}
                                placeholder="Enter data"
                              />
                              <Button
                                style={{
                                  width: "fit-content",
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                  backgroundColor: "#0A5F59",
                                  borderColor: "#0A5F59",
                                }}
                                onClick={(e) => {
                                  handleEditSave(child.id);
                                }}
                                type="submit"
                              >
                                Save
                              </Button>
                            </form>
                          </>
                        ) : (
                          child.name
                        )}

                        {isHovered === index &&
                          editMode !== index &&
                          (selectedPermission === "write" ||
                            selectedPermission === "admin") && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {!child.is_master && (
                                <img
                                  src={EditIcon}
                                  width={"14px"}
                                  alt="Edit Icon"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    handleEdit(index, child, ChildrenData.id);
                                  }}
                                />
                              )}
                              {selectedPermission === "admin" && (
                                <img
                                  src={DeleteIcon}
                                  width={"14px"}
                                  alt="Delete Icon"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => handleDelete(child.id)}
                                />
                              )}
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{ fontSize: "var(--text-font-size)", marginLeft: "10px" }}
            >
              No Data Found.
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
export default EcMappingTasksidebar;
