import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Add from "../../assets/images/add.svg";
import { getRandomColor, createImageFromInitials } from "../Utils";
import { postFunGoalKeyDecisions } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { resetExceptionError } from "../../store/actions/index.js";
import Alert from "../Alert.js";
import { motion } from "motion/react";

const KeyDecisionsPopUp = ({
  isEdit,
  setIsEdit,
  empData,
  isTaskSidebarOpen,
  setIsTaskSidebarOpen,
  setkeyDecisionsDisplay,
  sidebarData,
  setKeyDecisionData,
  keyDecisionData,
}) => {
  const dispatch = useDispatch();
  const ISError = useSelector((state) => state.ISError);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    when: "",
    assigned_to: "",
  });
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  useEffect(() => {
    if (empData && empData.length > 0) {
      empData.map((obj) => {
        if (obj.isselected === "true") {
          setSelectedOption({
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          });
        }
      });
    }
  }, [empData]);

  const handleOwnerChange = (selectedOptions) => {
    const updatedOptions = selectedOptions.filter((option) =>
      empData.some((emp) => emp.value === option.value)
    );
    setSelectedOption(updatedOptions);
    const updatedOptionsWithStructure = updatedOptions.map((option) => ({
      key: option.value,
      is_active: true,
    }));
    setFormData({
      ...formData,
      assigned_to: updatedOptionsWithStructure,
    });
  };
  useEffect(() => {
    if (sidebarData && isEdit) {
      setSelectedOption(
        sidebarData.assigned_to.filter((employee) => employee.is_active)
      );
      setSelectedId(sidebarData.key);
      setFormData({
        title: sidebarData.title || "",
        description: sidebarData.value || "",
        when: sidebarData.date_time || "",
        assigned_to: sidebarData.assigned_to,
      });

      console.log(sidebarData);
    }
  }, [sidebarData]);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.title !== "") {
      const selectedOwnerWithActive = empData.map((sowner) => ({
        key: sowner.value,
        is_active:
          formData.assigned_to &&
          formData.assigned_to.some(
            (selectedOwner) => selectedOwner.key === sowner.value
          ),
      }));
      if (isEdit) {
        setIsTaskSidebarOpen(false);
        setIsEdit(false);
        setkeyDecisionsDisplay(false);
        dispatch(
          postFunGoalKeyDecisions(
            false,
            false,
            formData.title,
            formData.description,
            formData.when,
            "edit",
            selectedOwnerWithActive,
            selectedId
          )
        );
      } else {
        dispatch(
          postFunGoalKeyDecisions(
            localStorage.getItem("functional_goal_id"),
            localStorage.getItem("goal_review_id"),
            formData.title,
            formData.description,
            formData.when,
            "add",
            selectedOwnerWithActive
          )
        );
        setIsTaskSidebarOpen(false);
        setkeyDecisionsDisplay(false);
      }
    } else {
      Alert.warning({
        title: "Warning",
        text: "Title should not be empty",
        html: ISError,
        icon: "warning",
        preConfirm: (isConfirm) => {
          if (isConfirm) {
            dispatch(resetExceptionError());
          } else {
          }
        },
      });
    }
  };
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };
  const handleFullScreen = () => {
    setFullScreenMode(true);
  };
  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  const closeTaskNav = () => {
    setIsTaskSidebarOpen(false);
    setkeyDecisionsDisplay(false);
    setIsEdit(false);
  };
  const handleSideNav = () => {
    setFullScreenMode(false);
  };
  const handleDelete = () => {
    if (isEdit) {
      const updatedItems = keyDecisionData.filter(
        (item) => item.key !== selectedId
      );
      setKeyDecisionData(updatedItems);
    }
    dispatch(
      postFunGoalKeyDecisions(
        false,
        false,
        false,
        false,
        false,
        "delete",
        false,
        selectedId
      )
    );
    setIsTaskSidebarOpen(false);
    setMenuOpen(false);
  };

  return (
    <div>
      {isTaskSidebarOpen && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 1 }}
          exit={{ x: "100%" }}
          style={{
            // width: fullScreenMode ? "85%" : "50%",
            width: fullScreenMode && "calc(100% - 50px)",
            zIndex: "100",
          }}
          className={"sidenav"}
        >
          <div className={"gtm-sidebar-header"}>
            <div
              style={{
                marginLeft: "10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Key Decisions
            </div>
            {!fullScreenMode ? (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}

                {isMenuOpen && (
                  <div className="menu">
                    <div className="Edit">
                      <div
                        className="DeleteOption"
                        onClick={(e) => handleDelete()}
                      >
                        <img
                          src={DeleteIcon}
                          width={"18px"}
                          style={{ marginRight: "10px" }}
                          alt="Edit Icon"
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                )}
                <a href="#" className="fullscreen" onClick={handleFullScreen}>
                  <AiOutlineCaretRight />
                </a>
              </>
            ) : (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                <a href="#" className="fullscreen" onClick={handleSideNav}>
                  <AiOutlineCaretLeft />
                </a>
              </>
            )}
            <a href="#" className="closebtn" onClick={() => closeTaskNav()}>
              &times;
            </a>
          </div>
          <div className="gtm-sidebar-body">
            <input
              style={{
                width: "98%",
                margin: "auto",
              }}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              className="form-control"
              value={formData.title}
              placeholder="Add your title"
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
            <br />
            <div
              className="row"
              style={{ justifyContent: "space-between", marginBottom: "10px" }}
            >
              <div
                className="col-6"
                style={{
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "0px 0px 0px 10px",
                    padding: "0px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Who
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Select
                    required
                    name="emp_id"
                    isMulti
                    hideSelectedOptions
                    allowSelectAll
                    styles={customStyles}
                    placeholder={
                      <span
                        style={{
                          fontSize: "var(--text-font-size)",
                          fontWeight: "400",
                        }}
                      >
                        Select Owner
                      </span>
                    }
                    isDisabled={
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? false
                        : true
                    }
                    value={selectedOption}
                    options={empData}
                    onChange={handleOwnerChange}
                    isSearchable={true}
                    filterOption={customFilter}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={e.icon} />
                        <span
                          style={{
                            marginLeft: 5,
                            fontSize: "var(--text-font-size)",
                            fontWeight: "400",
                          }}
                        >
                          {e.label}
                        </span>
                      </div>
                    )}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      // Option: EmpIconOption,
                    }}
                  />
                </div>
              </div>

              <div
                className="col-6"
                style={{
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    margin: "0px 0px 0px 10px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  When
                </h6>
                <input
                  disabled={
                    selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin"
                      ? false
                      : true
                  }
                  style={{ width: "100%", fontSize: "var(--text-font-size)" }}
                  type="date"
                  className="form-control"
                  value={formData.when}
                  onChange={(e) => handleInputChange("when", e.target.value)}
                />
              </div>
            </div>
            <h6
              style={{
                margin: "0px 0px 0px 10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Description
            </h6>
            <textarea
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              style={{
                fontSize: "var(--text-font-size)",
                width: fullScreenMode ? "98%" : "98%",
                minHeight: "48vh",
                margin: "auto",
              }}
              className="form-control"
              value={formData.description}
              placeholder="Add your description"
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </div>
          <br />
          <div className="strategy-sidebar-footer">
            <div>
              <Button
                type="button"
                style={{
                  height: "35px",
                  position: "absolute",
                  top: "8px",
                  right: "150px",
                  padding: "4px 10px",
                  backgroundColor: "var(--button-primary-color)",
                  fontSize: "var(--sub-heading-font-size)",
                  border: "1px solid var(--button-primary-color)",
                }}
                onClick={handleSubmit}
                disabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
              >
                Save
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default KeyDecisionsPopUp;
