import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectSummaryDetails,
  getTaskDetails,
  getXLStrategyPlanDetails,
} from "../../store/actions";
import "./reports.css";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import TaskSidebar from "../gtmExecution/TaskSidebar";
import keys from "../../utils/helpInfoKeys.json";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";
import { motion } from "motion/react";

const GanttChart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchProjectSummaryDetails = useSelector(
    (state) => state.fetchProjectSummaryDetails
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [viewMode, setViewMode] = useState("Week");
  const [isTaskSidebarOpen, setIsTaskSidebarOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selctedId, setSelectedId] = useState("");
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const closeTaskNav = () => {
    setIsTaskSidebarOpen(false);
  };

  useEffect(() => {
    if (fetchProjectSummaryDetails && fetchProjectSummaryDetails.tasks) {
      const tasks_data = fetchProjectSummaryDetails.tasks.map((task) => ({
        ...task,
        start: new Date(task.start),
        end: new Date(task.end),
      }));
      setTasks(tasks_data);
    }
  }, [fetchProjectSummaryDetails]);

  useEffect(() => {
    dispatch(
      getProjectSummaryDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        true
      )
    );
  }, []);
  const handleBack = () => {
    navigate("/projectsummary", { replace: true });
  };

  const handleClick = () => {
    navigate("/dashboard", { replace: true });
  };
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };
  const handleTaskClick = (task_data) => {
    dispatch(getTaskDetails(task_data.id));
    setIsTaskSidebarOpen(true);
    setSelectedId(task_data.id);
    setSelectedData(task_data);
  };

  const content = (
    <>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="gantt-chart-container"
      >
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Gantt Chart for Tasks
        </div>
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
        {tasks && tasks.length > 0 ? (
          <div className="gantt-chart-button">
            <button
              onClick={() => handleViewModeChange("Day")}
              className={viewMode === "Day" && "active"}
            >
              Day View
            </button>
            <button
              onClick={() => handleViewModeChange("Week")}
              className={viewMode === "Week" && "active"}
            >
              Week View
            </button>
            <button
              onClick={() => handleViewModeChange("Month")}
              className={viewMode === "Month" && "active"}
            >
              Month View
            </button>
            <button
              onClick={() => handleViewModeChange("Year")}
              className={viewMode === "Year" && "active"}
            >
              Year View
            </button>
          </div>
        ) : (
          <div>There is not tasks are available to show the Gantt Chart !!</div>
        )}
        {tasks && tasks.length > 0 && (
          <Gantt
            tasks={tasks}
            viewMode={viewMode}
            // onClick={() => handleTaskClick()}
            onClick={(taskId) => handleTaskClick(taskId)}
          />
        )}
        {isTaskSidebarOpen && (
          <TaskSidebar
            isNewTask={false}
            // selectedTaskMilestoneId={selctedId}
            breadcrumbs={"Gantt Chart > Tasks " + " > " + selectedData.name}
            selectedTaskId={selctedId}
            onClose={closeTaskNav}
            isSubTaskVisible={false}
          />
        )}
      </motion.div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showProducts={true}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Reports"}
        breadcrumbChild={"Gantt Chart"}
        documentName={"Gantt Chart"}
        helpKey={keys["Gantt Chart"].Name}
        image={"question"}
      />
    );
  }
};

export default GanttChart;
