import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cancel from "../assets/images/Cancel.svg";
import { motion } from "motion/react";

const VCTable = (props) => {
  const { selectedMenu } = props;
  let products = 0;
  if (props.productData) {
    products = props.productData.filter((c) => c.isactive === true).length;
  }
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const tableRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [theadHeight, setTheadHeight] = useState(0);
  useEffect(() => {
    if (selectedMenu && selectedMenu.key) {
      const calculateColumnWidths = () => {
        const thElements = tableRef.current.querySelectorAll("th");
        const tdElements = tableRef.current.querySelectorAll(
          ".data td:first-child"
        );
        const theadElements = tableRef.current.querySelectorAll("thead");
        if (theadElements[0]) {
          setTheadHeight(theadElements[0].offsetHeight);
        }
        const newColumnWidths = {};
        thElements.forEach((th) => {
          const key = th.getAttribute("data-key");
          const td = Array.from(tdElements).find(
            (td) => td.getAttribute("data-key") === key
          );

          const width = td ? td.offsetWidth : th.offsetWidth;
          newColumnWidths[key] = width;
        });

        setColumnWidths(newColumnWidths);
      };

      window.addEventListener("resize", calculateColumnWidths);
      calculateColumnWidths();

      return () => {
        window.removeEventListener("resize", calculateColumnWidths);
      };
    }
  }, [selectedMenu]);

  const getObjectsWithoutChildren = (data, data1) => {
    var c = 0;
    var check = true;
    if (data) {
      return data.reduce((result, item) => {
        if (item.isactive && (!item.children || item.children.length === 0)) {
          result.push(item);
        } else if (item.isactive && item.children && item.children.length > 0) {
          result.push(...getObjectsWithoutChildren(item.children, item));
        } else {
          c = c + 1;
          if (!item.isactive && data1) {
            if (data1.children.length === c) {
              result.push(data1);
            }
          } else {
            check = true;
          }
        }
        return result;
      }, []);
    } else {
      return [];
    }
  };

  const objectsWithoutChildren = getObjectsWithoutChildren(
    selectedMenu ? selectedMenu.columns : ""
  );
  const productsLength = products;

  return (
    <table ref={tableRef} className="bordered-table">
      <thead>
        <div
          style={{
            display: "flex",
            // alignItems: "center",
          }}
        >
          <HeaderSection
            data={
              selectedMenu !== undefined &&
              selectedMenu.columns.filter((column) => column.isactive === true)
            }
            // theadHeight={theadHeight}
            preview={props.preview}
          />
        </div>
      </thead>
      <tbody>
        {props.preview ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <tr>
              {objectsWithoutChildren &&
                columnWidths &&
                objectsWithoutChildren
                  .filter((column) => column.isactive === true)
                  .map((column) =>
                    column.key === "id" ? (
                      <td
                        style={{
                          padding: 5,
                          width: columnWidths[column.key],
                        }}
                      ></td>
                    ) : (
                      <td
                        style={{
                          padding: 5,
                          width: columnWidths[column.key],
                        }}
                      >
                        <div className="databars"></div>
                      </td>
                    )
                  )}
            </tr>
          </div>
        ) : (
          props.productData &&
          props.productData
            .filter((item) => item.isactive === true)
            .map((item) => (
              <BodySection
                selectedPermission={selectedPermission}
                key={item.id}
                preview={props.preview}
                previewvc={props.previewvc}
                showProductRemoval={props.showProductRemoval}
                columns={objectsWithoutChildren.filter(
                  (column) => column.isactive === true
                )}
                productsLength={productsLength}
                onProductSelectionChanged={props.onProductSelectionChanged}
                item={item}
                handleRemoveProduct={props.handleRemoveProduct}
                valueChain={props.valueChain}
                selectedMenu={selectedMenu}
                columnWidths={columnWidths}
              />
            ))
        )}
      </tbody>
    </table>
  );
};

const HeaderSection = ({
  data,
  theadHeight,
  direction = "row",
  preview,
  childheader,
}) => {
  var check = false;
  var c = 0;
  return (
    <>
      {data &&
        data
          .filter((obj) => obj.isactive === true)
          .map((column) => (
            <tr
              className={column.key === "id" ? "id-row" : ""}
              style={{ height: column.key === "id" ? theadHeight : "" }}
            >
              <th
                style={{
                  display: "block",
                  minWidth:
                    preview && column.key === "id"
                      ? 0
                      : column.key === "id"
                      ? 240
                      : 100,
                  // width:
                  //   column?.children && column.children.length
                  //     ? ""
                  //     : "max-content",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: column?.children && column.children.length ? "" : 100,
                  marginLeft: column.key === "id" ? "" : 10,
                  marginRight: column.key === "id" ? "" : 10,
                  fontWeight: childheader ? 300 : 300,
                  fontSize: "var(--sub-heading-font-size)",
                }}
                title={column.value}
                key={column.key}
                data-key={column.key}
                colSpan={column?.children ? column.children.length : 0}
                rowSpan={column?.children ? 0 : 4}
              >
                {column.key === "id" ? "" : column.value}
                {column?.children &&
                column.children.length &&
                column.children.filter((obj) => obj.isactive === true).length >
                  0 ? (
                  <hr
                    style={{
                      position: "relative",
                      borderBottom: "thin ridge",
                      margin: 0,
                      display: "block",
                      marginTop: 10,
                      borderTop: 0,
                    }}
                  />
                ) : (
                  <></>
                )}
              </th>
              {column?.children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  <HeaderSection
                    data={column.children}
                    theadHeight={theadHeight}
                    direction={"row"}
                    preview={preview}
                    childheader={true}
                  />
                </div>
              )}
            </tr>
          ))}
    </>
  );
};

const BodySection = ({
  preview,
  previewvc,
  item,
  productsLength,
  handleRemoveProduct,
  onProductSelectionChanged,
  columns,
  selectedMenu,
  showProductRemoval,
  valueChain,
  columnWidths,
  selectedPermission,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <tr key={item.id} style={{ flexShrink: 0 }}>
        {columns
          .filter((column) => column.isactive === true)
          .map((column) => (
            <td
              style={{
                padding: 5,
                width: columnWidths[column.key],
                boxSizing: "border-box",
                whiteSpace: "nowrap",
              }}
              key={column.key}
              data-key={column.key}
              className={column.key === "id" ? "products" : ""}
            >
              {column.key === "id" ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label
                    style={{
                      fontSize: "var(--text-font-size)",
                      width: "200px",
                      overflow: "hidden",
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.product_name}
                  </label>
                  <div>
                    {productsLength > 1 && showProductRemoval && (
                      <button
                        style={{
                          float: "right",
                          marginLeft: 10,
                          background: "white",
                          height: 17,
                          width: 17,
                          borderRadius: 17,
                          border: "1px solid #ccc",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: 3,
                        }}
                        onClick={() => handleRemoveProduct(item.id, valueChain)}
                      >
                        <img src={Cancel} width={18} height={18}></img>
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={
                      item[column.key] && !previewvc
                        ? "databars active"
                        : "databars"
                    }
                    style={{ width: columnWidths[column.key] + 10 }}
                    data-key={column.key}
                    onClick={(e) => {
                      if (
                        (selectedPermission === "write" ||
                          selectedPermission === "admin") &&
                        !(preview || previewvc)
                      ) {
                        onProductSelectionChanged(
                          item.id,
                          column.key,
                          selectedMenu.key,
                          !item[column.key],
                          valueChain
                        );
                      }
                    }}
                  ></div>
                </>
              )}
            </td>
          ))}
      </tr>
    </div>
  );
};

export default VCTable;
