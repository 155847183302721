import React, { useEffect, useState, useRef } from "react";
import { getRandomColor, createImageFromInitials } from "../Utils.js";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { postStrategyFunctions } from "../../store/actions/index.js";
import { StepProgressBar } from "../Utils.js";
import GoalSelector from "./GoalSelector.js";
import { resetExceptionError } from "../../store/actions/index.js";
import Alert from "../Alert.js";
import QuillEditor from "../../utils/QuillEditor.js";
import {
  postFolderManagerDelete,
  postLocalFolderManager,
  getLocalFolderManager,
  postLocalFolderManagerFileDelete,
  getXLExecutionPlanDetails,
} from "../../store/actions/index.js";
import { url } from "../../store/sagas/API.js";
import UploadImage from "../../assets/images/icon.svg";
import SheetImage from "../../assets/images/icons8-excel.svg";
import DocImage from "../../assets/images/icons8-microsoft-word.svg";
import PDFImage from "../../assets/images/pdf-svgrepo-com.svg";
import PPTImage from "../../assets/images/icons8-powerpoint.svg";
import TXTImage from "../../assets/images/icons8-text.svg";
import OtherImage from "../../assets/images/icons8-document.svg";
import NoDocImage from "../../assets/images/nodoc.svg";
import { motion } from "motion/react";

function StrategyPlanSidebar({
  isEdit,
  data,
  type,
  placeholder,
  goalData,
  empData,
  parentId,
  statusData,
  priorityData,
  breadcrumbs,
  functionIndex,
  impIndex,
  impid,
  milestoneIndex,
  handleMilestoneEdit,
  handleStrategicImpEdit,
  onClose,
  setIsSidebarOpen,
  handleStrategicImpDelete,
  handleMilestoneDelete,
  milestonedataId,
}) {
  const dispatch = useDispatch();
  const ISError = useSelector((state) => state.ISError);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [title, setTitle] = useState(isEdit ? data.name : "");
  const [progressHide, setProgressHide] = useState(false);
  const postStrategyFunctionDetails = useSelector(
    (state) => state.postStrategyFunctionDetails
  );
  const [assignedTo, setAssignedTo] = useState(
    isEdit ? data.assigned_to.filter((employee) => employee.is_active) : []
  );
  const [selectedGoals, setSelectedGoals] = useState(
    isEdit && type !== "milestone"
      ? data.goals_dt.filter((goal) => goal.is_active)
      : []
  );
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [progress, setProgress] = useState(isEdit ? data.progress : 0);
  const [desc, setDesc] = useState(isEdit ? data.description : "");
  const [status, setStatus] = useState(
    isEdit ? { label: data.status, value: data.status } : ""
  );
  const [priority, setPriority] = useState(
    isEdit ? { label: data.priority, value: data.priority } : ""
  );
  const [startDate, setstartDate] = useState(isEdit ? data.exp_start_date : "");
  const [duedate, setDuedate] = useState(isEdit ? data.exp_end_date : "");
  const initialCollaborators =
    isEdit && data.collaberators
      ? data.collaberators.filter((employee) => employee.is_active)
      : [];
  const [collaberators, setCollaberators] = useState(initialCollaborators);
  const [showMilestoneUploadFile, setShowMilestoneUploadFile] = useState(false);
  const [showImpUploadFile, setShowImpUploadFile] = useState(false);
  const [shownewUploadFile, setShowNewUploadFile] = useState(false);
  const [showNewUploadFileTaskId, setShowNewUploadFileTaskId] = useState(false);
  const [milestoneId, setMilestoneId] = useState(
    isEdit && type === "milestone" ? data.id : ""
  );
  const [functionalGoalId, setFunctionalGoalId] = useState(
    isEdit && type !== "milestone" ? data.id : ""
  );
  const [fullScreenMode, setFullScreenMode] = useState(false);
  useEffect(() => {
    if (impid) {
      setShowImpUploadFile(true);
      setShowMilestoneUploadFile(false);
    }
  }, [impid]);

  useEffect(() => {
    if (milestonedataId) {
      setShowMilestoneUploadFile(true);
      setShowImpUploadFile(false);
    }
  }, [milestonedataId]);
  useEffect(() => {
    if (postStrategyFunctionDetails && !isEdit) {
      if (postStrategyFunctionDetails.response) {
        setShowNewUploadFileTaskId(postStrategyFunctionDetails.response[0].key);
        setShowNewUploadFile(true);
      }
    }
  }, [postStrategyFunctionDetails]);
  useEffect(() => {
    if (!data.name) {
      setShowNewUploadFile(false);
    }
  }, []);
  const getProgress = (step) => {
    if (step === 0) {
      return 0;
    } else if (step === 20) {
      return 1;
    } else if (step === 40) {
      return 2;
    } else if (step === 60) {
      return 3;
    } else if (step === 80) {
      return 4;
    } else {
      return 5;
    }
  };
  const [currentStep, setCurrentStep] = useState(
    isEdit ? getProgress(data.progress) : 0
  );
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];

  const customStyles = (borderStyle) => ({
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      borderRadius: "10px",
      marginBottom: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
  });

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("strategyplan")) {
      setProgressHide(true);
    } else {
      setProgressHide(false);
    }
  }, []);
  const handleOwnerChange = (e) => {
    setAssignedTo(e);
  };
  const handleCollaboratorChange = (e) => {
    setCollaberators(e);
  };

  const handleFullScreen = () => {
    setFullScreenMode(true);
  };

  const handleSideNav = () => {
    setFullScreenMode(false);
  };

  const handleStatusSelect = (selectedOptions) => {
    setStatus(selectedOptions);
  };

  const handlePrioritySelect = (selectedOptions) => {
    setPriority(selectedOptions);
  };

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleDescOnChange = (content) => {
    setDesc(content);
  };

  const handleSave = () => {
    if (
      selectedSubMenuPermission === "write" ||
      selectedSubMenuPermission === "admin"
    ) {
      if (type === "milestone") {
        if (title !== "") {
          if (isEdit) {
            // setIsSidebarOpen(false);
            let assignedToList = [];
            if (Array.isArray(assignedTo)) {
              assignedToList = assignedTo;
            } else {
              assignedToList = [assignedTo];
            }
            const requiredAssignedData = data.assigned_to.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const updatedAssignedData = data.assigned_to.map((item) => ({
              ...item,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));

            let milestone_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                milestone: title,
                milestone_id: milestoneId,
                status: status.value,
                start_date: startDate || "",
                due_date: duedate || "",
                priority: priority.value,
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];

            handleMilestoneEdit(
              functionIndex,
              impIndex,
              milestoneIndex,
              milestone_data,
              updatedAssignedData
            );
          } else {
            const assignedToList = [assignedTo];
            const requiredAssignedData = empData.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));

            let milestone_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                prod_id: localStorage.getItem("product_id"),
                imperative_id: parentId,
                milestone: title,
                milestone_id: isEdit ? milestoneId : false,
                status: status.value || "",
                start_date: startDate,
                due_date: duedate,
                priority: priority.value || "",
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];
            dispatch(
              postStrategyFunctions(
                milestone_data,
                "milestone",
                false,
                false,
                true
              )
            );
          }
        } else {
          Alert.warning({
            title: "Warning",
            text: "Milestone name should not be empty",
            html: ISError,
            icon: "warning",
            preConfirm: (isConfirm) => {
              if (isConfirm) {
                dispatch(resetExceptionError());
              } else {
              }
            },
          });
        }
      } else {
        if (title !== "") {
          if (isEdit) {
            // setIsSidebarOpen(false);
            let assignedToList = [];
            if (Array.isArray(assignedTo)) {
              assignedToList = assignedTo;
            } else {
              assignedToList = [assignedTo];
            }
            const requiredAssignedData = empData.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const updatedAssignedData = empData.map((item) => ({
              ...item,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const selectedGoalsWithActive = data.goals_dt.map((goal) => ({
              key: goal.value,
              is_active: selectedGoals.some(
                (selectedGoal) => selectedGoal.value === goal.value
              ),
            }));
            const updatedGoalsData = data.goals_dt.map((goal) => ({
              ...goal,
              is_active: selectedGoals.some(
                (selectedGoal) => selectedGoal.value === goal.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const updatedCollaboratorsData = empData.map((item) => ({
              ...item,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            let functional_goal_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                product_id: localStorage.getItem("product_id"),
                function_id: parentId,
                imperative_name: title,
                imperative_id: isEdit ? functionalGoalId : false,
                selected_goal: selectedGoalsWithActive,
                status: "Open",
                due_date: duedate,
                priority: "High",
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];
            handleStrategicImpEdit(
              functionIndex,
              impIndex,
              functional_goal_data,
              updatedAssignedData,
              updatedGoalsData,
              updatedCollaboratorsData
            );
          } else {
            const assignedToList = [assignedTo];
            const requiredAssignedData = empData.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const selectedGoalsWithActive = goalData.map((goal) => ({
              key: goal.value,
              is_active: selectedGoals.some(
                (selectedGoal) => selectedGoal.value === goal.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            let functional_goal_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                product_id: localStorage.getItem("product_id"),
                function_id: parentId,
                imperative_name: title,
                imperative_id: isEdit ? functionalGoalId : false,
                selected_goal: selectedGoalsWithActive,
                status: "Open",
                due_date: duedate,
                priority: "High",
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];
            dispatch(
              postStrategyFunctions(
                functional_goal_data,
                "functional_goal",
                false,
                true
              )
            );
          }
        } else {
          Alert.warning({
            title: "Warning",
            text: "Functional Goal name should not be empty",
            html: ISError,
            icon: "warning",
            preConfirm: (isConfirm) => {
              if (isConfirm) {
                dispatch(resetExceptionError());
              } else {
              }
            },
          });
        }
      }
      // setIsSidebarOpen(false);
    }
    // setShowNewUploadFile(true)
  };

  const logo = createImageFromInitials(
    30,
    localStorage.getItem("full_name"),
    getRandomColor(localStorage.getItem("full_name"))
  );

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
    setProgress(stepIndex * 20);
  };

  const handleGoalChange = (selectedOptions) => {
    setSelectedGoals(selectedOptions);
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 1 }}
      exit={{ x: "100%" }}
      style={{
        width: fullScreenMode && "calc(100% - 50px)",
        zIndex: "100",
      }}
      className={"sidenav"}
    >
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: "1",
            minWidth: "70%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
            paddingTop: "4px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
            paddingLeft: "10px",
          }}
          title={breadcrumbs}
        >
          {breadcrumbs}
        </div>
        {!fullScreenMode ? (
          <>
            {type === "milestone" ? (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                {isMenuOpen && (
                  <div className="menu">
                    <div className="Edit">
                      <div
                        className="DeleteOption"
                        onClick={() => {
                          handleMilestoneDelete(
                            functionIndex,
                            impIndex,
                            milestoneIndex,
                            milestonedataId
                          );
                          onClose();
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          width={"18px"}
                          style={{ marginRight: "10px" }}
                          alt="Edit Icon"
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                {isMenuOpen && (
                  <div className="menu">
                    <div className="Edit">
                      <div
                        className="DeleteOption"
                        onClick={() => {
                          handleStrategicImpDelete(
                            functionIndex,
                            impIndex,
                            impid
                          );
                          onClose();
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          width={"18px"}
                          style={{ marginRight: "10px" }}
                          alt="Edit Icon"
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <a href="#" className="fullscreen" onClick={handleFullScreen}>
              <AiOutlineCaretRight />
            </a>
          </>
        ) : (
          <>
            {(selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <a href="#" className="sideMenu" onClick={handleMenuClick}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </a>
            )}
            <a href="#" className="fullscreen" onClick={handleSideNav}>
              <AiOutlineCaretLeft />
            </a>
          </>
        )}
        <a href="#" className="closebtn" onClick={onClose}>
          &times;
        </a>
      </div>
      <div className="gtm-sidebar-body">
        <div>
          <input
            type="text"
            placeholder={placeholder}
            value={title}
            style={{
              marginLeft: "10px",
              width: "95%",
              border: "none",
              marginBottom: "10px",
              fontSize: "var(--task-title-font-size)",
            }}
            onChange={(e) => setTitle(e.target.value)}
            disabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          />
        </div>

        <div className="row">
          <div
            className="col-6"
            style={{
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Owner
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Select
                required
                name="emp_id"
                styles={customStyles}
                placeholder={
                  <span style={{ fontSize: "var(--text-font-size)" }}>
                    Select Owner
                  </span>
                }
                value={assignedTo}
                options={empData}
                onChange={handleOwnerChange}
                isSearchable={true}
                filterOption={customFilter}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img alt="" src={e.icon} />
                    <span
                      style={{
                        marginLeft: 5,
                        fontSize: "var(--text-font-size)",
                      }}
                    >
                      {e.label}
                    </span>
                  </div>
                )}
                isDisabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>

          <div
            className="col-6"
            style={{
              display: type === "milestone" ? "block" : "none",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 999,
            }}
          >
            <div
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Status
            </div>
            <Select
              required
              styles={customStyles}
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Status
                </span>
              }
              value={status}
              options={statusData}
              onChange={handleStatusSelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{
              display: type === "functional_goal" ? "block" : "none",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 999,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "50px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Goals
            </div>
            <div style={{ marginLeft: "10px" }}>
              <GoalSelector
                selectedGoals={selectedGoals}
                options={goalData}
                value={selectedGoals}
                handleGoalChange={handleGoalChange}
                disabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: type === "milestone" ? "block" : "none",
              alignItems: "center",
            }}
          >
            <span
              style={{
                marginLeft: "10px",
                marginBottom: "10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
                whiteSpace: "nowrap",
              }}
            >
              Start Date
            </span>
            <input
              style={{
                marginLeft: "10px",
                width: "98%",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
          <div
            className="col-6"
            style={{
              display: type === "milestone" ? "block" : "none",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
                whiteSpace: "nowrap",
              }}
            >
              Due Date
            </div>
            <input
              style={{
                width: "100%",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control"
              value={duedate}
              onChange={(e) => setDuedate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>

        <div
          className="row"
          style={{ display: type === "milestone" ? "block" : "none" }}
        >
          <div
            className="col-6"
            style={{
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 999,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "40px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Priority
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Select
                required
                styles={customStyles}
                placeholder={
                  <span style={{ fontSize: "var(--text-font-size)" }}>
                    Select priority
                  </span>
                }
                value={priority}
                options={priorityData}
                onChange={handlePrioritySelect}
                isSearchable={true}
                filterOption={customFilter}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        marginLeft: 5,
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      {e.label}
                    </span>
                  </div>
                )}
                isDisabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>

        {!progressHide && (
          <div className="row">
            <div
              className="col-12"
              style={{
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  marginLeft: "10px",
                  marginRight: "20px",
                  marginBottom: 0,
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Progress
              </h6>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "15px",
                  marginRight: "10px",
                }}
              >
                <StepProgressBar
                  steps={steps}
                  currentStep={currentStep}
                  onStepClick={handleStepClick}
                  selectedSubMenuPermission={selectedSubMenuPermission}
                />
                <div className="step-percentage">
                  {(currentStep / (steps.length - 1)) * 100}%
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginBottom: "0px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
            marginLeft: "10px",
          }}
        >
          Description
        </div>
        <div style={{ margin: "10px 10px 30px 10px", height: "300px" }}>
          <QuillEditor
            value={desc}
            onChange={handleDescOnChange}
            height={"300px"}
          />
        </div>
        <br />
        {/* <div style={{ display: "flex", width: "100%" }}>
          <h6
            style={{
              marginLeft: "20px",
              marginBottom: "0px",
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
            }}
          >
            Collaborators
          </h6>

          <Select
            required
            name="emp_id"
            isMulti
            className="select-collaborator"
            hideSelectedOptions
            allowSelectAll
            styles={customStyles}
            filterOption={customFilter}
            placeholder="Select"
            value={collaberators}
            options={empData}
            onChange={handleCollaboratorChange}
            isSearchable={true}
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="" src={e.icon} />
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            )}
            menuPlacement="top"
            isDisabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          />
        </div> */}
        {showImpUploadFile && isEdit ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={impid}
              attachments={isEdit ? data.attachment : ""}
              isEdit={isEdit}
            />
          </>
        ) : showMilestoneUploadFile && isEdit ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={milestonedataId}
              attachments={isEdit ? data.attachment : ""}
              isEdit={isEdit}
            />
          </>
        ) : shownewUploadFile && !isEdit ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={showNewUploadFileTaskId}
              isEdit={isEdit}
            />
          </>
        ) : null}
      </div>
      <div>
        <div>
          <Button
            type="button"
            style={{
              position: "absolute",
              top: "8px",
              right: "150px",
              padding: "4px 10px",
              backgroundColor: "var(--button-primary-color)",
              fontSize: "var(--sub-heading-font-size)",
              border: "1px solid var(--button-primary-color)",
            }}
            onClick={() => handleSave()}
            disabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          >
            Save
          </Button>
        </div>
      </div>
    </motion.div>
  );
}

const UploadFiles = ({
  selectedSubMenuPermission,
  attachments,
  taskId,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);

  const fetchpostLocalManagerData = useSelector(
    (state) => state.fetchpostLocalManagerData
  );
  const fetchpostLocalFolderManagerFileDelete = useSelector(
    (state) => state.fetchpostLocalFolderManagerFileDelete
  );

  useEffect(() => {
    if (attachments && taskId) {
      setFiles(attachments);
    }
  }, [attachments]);

  useEffect(() => {
    if (fetchpostLocalManagerData && fetchpostLocalManagerData.status === 200) {
      if (fetchpostLocalManagerData.message) {
        const attachedToNames = fetchpostLocalManagerData.message.map(
          (item) => item.attached_to_name
        );
        if (attachedToNames.includes(taskId)) {
          setFiles((prevFiles) => [
            ...prevFiles,
            ...fetchpostLocalManagerData.message,
          ]);
        }
      }
    }
  }, [fetchpostLocalManagerData]);

  useEffect(() => {
    if (
      fetchpostLocalFolderManagerFileDelete &&
      fetchpostLocalFolderManagerFileDelete.status === 200
    ) {
      if (isEdit) {
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles?.filter(
            (existingItem) =>
              !fetchpostLocalFolderManagerFileDelete.file_id.includes(
                existingItem.name
              )
          );
          return updatedFiles;
        });
      }
    }
  }, [fetchpostLocalFolderManagerFileDelete]);

  const handleDeleteFile = (id) => {
    Alert.warning({
      title: "Warning",
      // html: ISError,
      text: "Are you sure do you want to delete the File?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          dispatch(postLocalFolderManagerFileDelete(id));
          dispatch(resetExceptionError());
        }
      },
    });
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };
  const [isMSDocsSend, setIsMSDocsSend] = useState(false);

  function handleFile(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setIsMSDocsSend(!isMSDocsSend);
      if (taskId) {
        dispatch(
          postLocalFolderManager(
            localStorage.getItem("bi_id"),
            localStorage.getItem("screen_id"),
            localStorage.getItem("main_screen_id"),
            file,
            "",
            "",
            localStorage.getItem("product_id"),
            taskId
          )
        );
      }
    }
  }
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  return (
    <div>
      <div className="row">
        <div>
          <form
            id="form-file-upload"
            onDragEnter={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? handleDrag
                : null
            }
            onSubmit={(e) => e.preventDefault()}
            style={{
              height: "60px",
              width: "80%",
              margin: "auto",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleChange}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <button
                  className="upload-button"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    onButtonClick();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor:
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? "pointer"
                        : "default",
                    textDecoration:
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? ""
                        : "none",
                  }}
                >
                  <div>
                    <img src={UploadImage} />
                  </div>
                  <span
                    style={{
                      color: "#0DA14B",
                      marginLeft: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Upload a file
                  </span>
                  <span
                    style={{
                      paddingLeft: "10px",
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    or drag and drop a file
                  </span>
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
          <div>
            {files.length > 0 ? (
              <>
                <table className="bordered-table-upload-files">
                  <thead
                    style={{
                      backgroundColor: "#F6F8FA",
                    }}
                  >
                    <tr>
                      <th>File</th>
                      <th>Modified</th>
                      <th>Modified By</th>
                      {selectedSubMenuPermission === "admin" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 &&
                      files.map((obj) => (
                        <tr key={obj.name}>
                          <td style={{ textAlign: "left" }}>
                            <a
                              href={url + obj.file_url}
                              target="_blank"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                src={
                                  obj.img
                                    ? url + obj.img
                                    : obj.file_name.split(".").pop() ===
                                        "xls" ||
                                      obj.file_name.split(".").pop() ===
                                        "xlsx" ||
                                      obj.file_name.split(".").pop() === "csv"
                                    ? SheetImage
                                    : obj.file_name.split(".").pop() === "pdf"
                                    ? PDFImage
                                    : obj.file_name.split(".").pop() ===
                                        "doc" ||
                                      obj.file_name.split(".").pop() === "docx"
                                    ? DocImage
                                    : obj.file_name.split(".").pop() === "ppt"
                                    ? PPTImage
                                    : OtherImage
                                }
                                style={{
                                  width: 25,
                                  height: 25,
                                }}
                              />
                              <label
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  fontSize: "var(--text-font-size)",
                                  width: "300px",
                                }}
                              >
                                {obj.file_name}
                              </label>
                            </a>
                          </td>
                          <td style={{ fontSize: "var(--text-font-size)" }}>
                            {obj.modified}
                          </td>
                          <td style={{ fontSize: "var(--text-font-size)" }}>
                            {obj.modified_by}
                          </td>
                          {selectedSubMenuPermission === "admin" && (
                            <td>
                              <span
                                className="folder-managementicons"
                                title="Delete"
                                onClick={() => handleDeleteFile(obj.name)}
                              >
                                <img src={DeleteIcon} width={14} />
                              </span>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <label
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                    marginLeft: "10px",
                  }}
                >
                  All Documents
                </label>
                <div style={{ textAlign: "center" }}>
                  <img src={NoDocImage} />
                  <div>
                    <label
                      style={{
                        fontSize: "var(--sub-heading-font-size)",
                        fontWeight: "var(--heading-font-weight)",
                      }}
                    >
                      No Documents
                    </label>
                  </div>
                  <div>
                    <label
                      style={{
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      Uploaded documents will appear here
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategyPlanSidebar;
