import React from "react";

/**
 */
const RightElement = ({
  xAxisData,
  data,
  _onClickGraphActionHandler,
  _updateAxisElementData,
}) => {
  function hexToRgb(hex) {
    const bigint = parseInt(hex.replace("#", ""), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  return (
    <div
      style={{
        width: 450,
        marginLeft: "30%",
        position: "relative",
        top: "-55px",
      }}
    >
      <table border="0">
        <tbody>
          {data.map((list) => (
            <tr
              key={list.key}
              style={{
                textAlign: "center",
                fontSize: "var(--captions-font-size)",
              }}
            >
              {list.child.map((el, index) => (
                <td
                  key={el.key + index}
                  colSpan={el.colSpan}
                  rowSpan={el.rowSpan}
                  style={{
                    height: 95,
                    backgroundColor:
                      el.isselected || el.isselected === ""
                        ? `rgba(${hexToRgb(el.color)}, 1)`
                        : "#EEEDEB",
                    // color:el.isselected || el.isselected === "" ? '':'gray',
                    cursor: "pointer",
                    border: "1px solid #D0D4CA",
                    color:
                      el.isselected || el.isselected === ""
                        ? `${el.textColor}`
                        : "gray",

                    // transform: el.isselected ? "translate(-5px,-5px)" : "none",
                    // transition: "transform 0.3s ease-in-out",
                    // transformOrigin: "bottom left",
                    // boxShadow: el.isselected ? `5px 5px 0px ${el.color}`:'0px 0px 0px rgba(0, 0, 0, 0)',
                    fontSize: "var(--captions-font-size)",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  onClick={() => {
                    _updateAxisElementData(el);
                    _onClickGraphActionHandler(el);
                  }}
                >
                  {el.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {xAxisData !== null &&
            xAxisData.map((list, index) => (
              <tr key={index} style={{ textAlign: "center" }}>
                {list.data.map((el) => (
                  <td
                    key={el.key + index}
                    rowSpan={el.rowSpan}
                    colSpan={el.colSpan}
                    style={{
                      fontSize: "var(--captions-font-size)",
                      border: "1px solid #000000",
                      borderColor: "rgb(234, 234, 234) rgb(234, 234, 234)",
                      width: 110 * parseInt(el.colSpan),
                      cursor: "pointer",
                      color: el.isselected ? "black" : "black",
                      backgroundColor: el.isselected ? "rgb(151 245 187)" : "",
                    }}
                    onClick={() => {
                      _updateAxisElementData({ ...el, isProduct: true }, "X");
                    }}
                  >
                    {el.value}
                  </td>
                ))}
              </tr>
            ))}
        </tfoot>
      </table>
    </div>
  );
};

export default RightElement;
