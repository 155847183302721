import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const LeftElement = ({
  questionsData,
  _onChangeActionHandler,
  _updateAxisElementData,
  handleUpdatedQuestions,
}) => {
  const selectedPermission = useSelector((state) => state.selectedPermission);

  const [selectedOptions, setSelectedOptions] = useState({});
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState(questionsData);
  const [selectedOption, setSelectedOption] = useState("");
  /**
   * Force update questions array data
   * */
  useEffect(() => {
    setQuestions([...questionsData]);
  }, [questionsData]);

  const handleOptionSelect = (
    questionKey,
    optionDetails,
    isProduct,
    isReverseCheck = false
  ) => {
    let updatedQuestions = [...questions];
    let actionedIndex = "";
    if (optionDetails) {
      setSelectedOption(optionDetails["value"]);
    }
    updatedQuestions = updatedQuestions.map((obj, index) => {
      if (obj.key === questionKey) {
        actionedIndex = index;
        let updatedOptions = obj.options.map((obj1) => {
          if (obj.ans_type === "Checkbox") {
            if (obj1.key === optionDetails.key) {
              obj1 = { ...obj1, isactive: !obj1.isactive };
            }
          } else {
            if (obj1.key === optionDetails.key) {
              obj1 = { ...obj1, isactive: !obj1.isactive };
            } else {
              obj1 = { ...obj1, isactive: false };
            }
          }
          return obj1;
        });
        obj = { ...obj, options: updatedOptions };
      } else if (actionedIndex !== "" && index > actionedIndex) {
        let updatedOptions = obj.options.map((obj1) => {
          obj1 = { ...obj1, isactive: false };
          return obj1;
        });
        obj = { ...obj, options: updatedOptions };
      }
      return obj;
    });
    _onChangeActionHandler(
      { ...optionDetails, isProduct: isProduct },
      isReverseCheck,
      updatedQuestions
    );
    _updateAxisElementData(optionDetails);
    setQuestions(updatedQuestions);
    handleUpdatedQuestions(updatedQuestions);
  };

  const handleSubmit = () => {
    let newScore = 0;

    questions.forEach((question) => {
      if (selectedOptions[question.key] === question.answer) {
        newScore++;
      }
    });

    setScore(newScore);
  };

  return (
    <div
      style={{
        overflow: "none",
        // marginLeft: 60,
        // marginRight: 60,
      }}
    >
      {questions.map((question, index) => (
        <div key={question.key} style={{ marginBottom: 15 }}>
          <p
            style={{
              marginBottom: 0,
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            {question.value}
          </p>
          <div style={{ display: "flex" }}>
            {question.options.map((option) => (
              <div
                key={option.key}
                style={{
                  cursor: "pointer",
                  margin: 5,
                  padding: 10,
                  width: 180,
                  border: "thin ridge ",
                  borderColor: "#a7a7a7",
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: option.isactive ? "#d9f6e4" : "transparent",
                  // color:
                  //   selectedOption === "New Products" &&
                  //   question.isProduct === true &&
                  //   (option.value === "Existing Use cases" ||
                  //     option.value === "New Use cases")
                  //     ? "gray"
                  //     : "black",
                  color:
                    selectedOption === "New Products"
                      ? option.value === "Existing Use cases" ||
                        option.value === "New Use cases"
                        ? "gray"
                        : "black"
                      : selectedOption === "New Markets"
                      ? option.value === "Existing Customers" ||
                        option.value === "New Customers" ||
                        option.value === "Existing BU" ||
                        option.value === "New BU"
                        ? "gray"
                        : "black"
                      : "black",
                  cursor:
                    selectedOption === "New Products"
                      ? option.value === "Existing Use cases" ||
                        option.value === "New Use cases"
                        ? "not-allowed"
                        : "pointer"
                      : selectedOption === "New Markets"
                      ? option.value === "Existing Customers" ||
                        option.value === "New Customers" ||
                        option.value === "Existing BU" ||
                        option.value === "New BU"
                        ? "not-allowed"
                        : "pointer"
                      : "pointer",
                  // borderColor:
                  //   activeOptionKey === option.key && !isOptionDisabled
                  //     ? "#81cea1"
                  //     : "#a7a7a7",
                  transition: "background-color 0.2s ease",
                }}
                className={`option-container ${
                  option.isactive ? "selected" : ""
                }`}
                onClick={() => {
                  let filteredQuestions = questions.filter(
                    (el) => el.isProduct === question.isProduct
                  );
                  let checkActiveRecord = filteredQuestions
                    .map((list) => {
                      if (list.options.filter((el) => el.isactive).length > 0) {
                        return list;
                      } else {
                        return null;
                      }
                    })
                    .filter((el) => el !== null);
                  if (question.isProduct) {
                    if (index === 0) {
                      handleOptionSelect(
                        question.key,
                        option,
                        question.isProduct
                      );
                    } else {
                      if (checkActiveRecord.length > 0) {
                        if (
                          questions[index - 1].options.filter(
                            (el) => el.isactive
                          ).length > 0 &&
                          questions[index - 1].options
                            .filter((el) => el.isactive)[0]
                            .keyArray.includes(option.key)
                        )
                          handleOptionSelect(
                            question.key,
                            option,
                            question.isProduct
                          );
                        else
                          handleOptionSelect(
                            question.key,
                            option,
                            question.isProduct,
                            true
                          );
                      } else {
                        handleOptionSelect(
                          question.key,
                          option,
                          question.isProduct,
                          true
                        );
                      }
                    }
                  } else {
                    if (
                      filteredQuestions[0].key === question.key ||
                      checkActiveRecord.length > 0
                    ) {
                      let checkOptions = questions[index - 1].options.filter(
                        (el) => el.isactive
                      );
                      if (filteredQuestions[0].key === question.key)
                        handleOptionSelect(
                          question.key,
                          option,
                          question.isProduct
                        );
                      else if (
                        checkOptions.length > 0 &&
                        checkOptions[0].keyArray.includes(option.key)
                      )
                        handleOptionSelect(
                          question.key,
                          option,
                          question.isProduct
                        );
                      else
                        handleOptionSelect(
                          question.key,
                          option,
                          question.isProduct,
                          true
                        );
                    } else {
                      handleOptionSelect(
                        question.key,
                        option,
                        question.isProduct,
                        true
                      );
                    }
                  }
                }}
              >
                <div style={{ marginLeft: 5 }}>
                  {question.ans_type === "Checkbox" ? (
                    <input
                      type="checkbox"
                      id={option.key}
                      className="form-check-input"
                      name={question.key}
                      checked={option.isactive}
                      style={{ marginTop: 5, cursor: "pointer" }}
                      onChange={() => {}}
                    />
                  ) : (
                    <input
                      type="radio"
                      id={option.key}
                      className="form-check-input"
                      name={question.key}
                      checked={option.isactive}
                      style={{ marginTop: 5, cursor: "pointer" }}
                      onChange={() => {}}
                    />
                  )}
                </div>
                <label
                  style={{
                    paddingLeft: 5,
                    paddingTop: 0,
                    marginTop: 2,
                    fontWeight: 400,
                    fontSize: 14,
                    fontSize: "var(--text-font-size)",
                    cursor:
                      selectedOption === "New Products"
                        ? option.value === "Existing Use cases" ||
                          option.value === "New Use cases"
                          ? "not-allowed"
                          : "pointer"
                        : selectedOption === "New Markets"
                        ? option.value === "Existing Customers" ||
                          option.value === "New Customers" ||
                          option.value === "Existing BU" ||
                          option.value === "New BU"
                          ? "not-allowed"
                          : "pointer"
                        : "pointer",
                    // color: !isOptionDisabled ? 'black':'gray',
                  }}
                >
                  {option.value}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeftElement;
