import React, { useEffect, useRef, useState } from "react";
import MainComponent from "../MainComponent";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import keys from "../../utils/helpInfoKeys.json";

import "./XLStrategyPlan.css";
import CommentSection from "../../utils/CommentSection.js";
import Login from "../Login.js";
import {
  getXLStrategyPlanDetails,
  postXLStrategyPlanDetails,
} from "../../store/actions/index.js";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo.js";
import { motion } from "motion/react";

function XLStrategyPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/executionplan");
  };
  const handleBack = () => {
    navigate("/dashboard");
  };
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [formData, setFormData] = useState({
    opportunity_description: "",
    value_proposition: "",
    opportunity_value: "",
    customer_needs: "",
    target_customer_segments: "",
    key_players: "",
    substitutes: "",
    revenue_potential: "",
    monetisation_model_and_pricing: "",
    use_cases: "",
    distribution: "",
    key_risks_and_mitigation: "",
    operational_model: "",
    key_enablers_and_investment: "",
  });
  const [prevData, setPrevData] = useState("");
  const fetchgetXLStrategyPlanData = useSelector(
    (state) => state.fetchgetXLStrategyPlanData
  );
  useEffect(() => {
    dispatch(
      getXLStrategyPlanDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);
  useEffect(() => {
    if (fetchgetXLStrategyPlanData) {
      if (fetchgetXLStrategyPlanData?.data.length > 0) {
        console.log("fetchgetXLStrategyPlanData", fetchgetXLStrategyPlanData);
        setFormData(fetchgetXLStrategyPlanData.data[0]);
        setPrevData(fetchgetXLStrategyPlanData.data[0]);
      }
    }
  }, [fetchgetXLStrategyPlanData]);

  const typingTimeoutRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (JSON.stringify(prevData) !== JSON.stringify(formData)) {
        dispatch(
          postXLStrategyPlanDetails(
            localStorage.getItem("bi_id"),
            localStorage.getItem("product_id"),
            updatedFormData
          )
        );
      }
    }, 2000);
  };

  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      <form className="strategyplan-form-layout">
        <div className="strategyplan-form-container-layout">
          <div className="container-layout-heading">Ambition</div>
          <div className="container-layout1">
            <div className="sub-container-layout">
              <TextComponent
                label="Opportunity Description/ Market Need"
                rows={6}
                name="opportunity_description"
                value={formData.opportunity_description}
                handleChange={handleChange}
              />
            </div>
            <div className="sub-container-layout">
              <TextComponent
                label="Value Proposition"
                rows={6}
                name="value_proposition"
                value={formData.value_proposition}
                handleChange={handleChange}
              />
            </div>
            <div className="box-number-input">
              <label>Opportunity Value</label>
              <textarea
                type="text"
                name="opportunity_value"
                value={formData.opportunity_value}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="strategyplan-form-container-layout">
          <div className="container-layout-heading">Customer and Segments</div>
          <div className="container-layout2">
            <div className="sub-container-layout">
              <div className="">
                <TextComponent
                  label="Customer Needs"
                  rows={10}
                  name="customer_needs"
                  value={formData.customer_needs}
                  handleChange={handleChange}
                />
              </div>
              <div className="">
                <TextComponent
                  label="Key Players"
                  rows={10}
                  name="key_players"
                  value={formData.key_players}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="sub-container-layout">
              <TextComponent
                label="Target Customer Segments"
                rows={10}
                name="target_customer_segments"
                value={formData.target_customer_segments}
                handleChange={handleChange}
              />
              <TextComponent
                label="Substitutes"
                rows={10}
                name="substitutes"
                value={formData.substitutes}
                handleChange={handleChange}
              />
            </div>
            <div className="box-number-input">
              <label>Revenue Potential </label>
              <textarea
                type="text"
                name="revenue_potential"
                value={formData.revenue_potential}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="strategyplan-form-container-layout">
          <div className="container-layout-heading">GTM and Execution</div>
          <div className="container-layout3">
            <div className="sub-container-layout">
              <TextComponent
                label="Monetisation Model and Pricing"
                rows={6}
                name="monetisation_model_and_pricing"
                value={formData.monetisation_model_and_pricing}
                handleChange={handleChange}
              />
              <TextComponent
                label="Distribution / Channel / Mix"
                rows={6}
                name="distribution"
                value={formData.distribution}
                handleChange={handleChange}
              />
              <TextComponent
                label="Operational Model"
                rows={6}
                name="operational_model"
                value={formData.operational_model}
                handleChange={handleChange}
              />
            </div>
            <div className="sub-container-layout">
              <TextComponent
                label=" Use Cases"
                rows={6}
                name="use_cases"
                value={formData.use_cases}
                handleChange={handleChange}
              />
              <TextComponent
                label="Key Risks and Mitigation"
                rows={6}
                name="key_risks_and_mitigation"
                value={formData.key_risks_and_mitigation}
                handleChange={handleChange}
              />
              <TextComponent
                label="Key Enablers and Investment"
                rows={6}
                name="key_enablers_and_investment"
                value={formData.key_enablers_and_investment}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
      {/* <div>
        <CommentSection />
      </div> */}
      <div>{selectedHelpInfoSidebar && <SideNavHelpInfo />}</div>
    </motion.div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          // showTemplate={true}
          showSelector={true}
          showProducts={true}
          showButtons={true}
          handleNext={handleClick}
          handleBackButton={handleBack}
          breadcrumbParent={"GTM Execution"}
          breadcrumbChild={"XL Strategy Plan"}
          documentName={"XL Strategy Plan"}
          helpKey={keys["XL Strategy Plan"].Name}
          image={"question"}
        />
      </>
    );
  }
}

export default XLStrategyPlan;

export const TextComponent = ({ label, rows, name, value, handleChange }) => {
  return (
    <>
      <label>{label}</label>
      <textarea
        rows={rows}
        name={name}
        className="form-control"
        value={value}
        onChange={handleChange}
      />
    </>
  );
};
