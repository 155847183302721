import React, { useEffect, useState } from "react";
import MainComponent from "./MainComponent";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getNotes, postNotes } from "../store/actions";
import { useNavigate } from "react-router-dom";
import Cancel from "../assets/images/Cancel.svg";
import Login from "./Login";
import { motion } from "motion/react";

const ListOfNotes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getNotesData = useSelector((state) => state.getNotesData);
  const [notesData, setNotesData] = useState("");
  const isNotesDataFetched = useSelector((state) => state.isNotesDataFetched);
  const postedNotesData = useSelector((state) => state.postedNotesData);
  
  useEffect(() => {
    dispatch(
      getNotes(localStorage.getItem("note_id"), localStorage.getItem("bi_id"))
    );
  }, []);
  useEffect(() => {
    if (getNotesData && getNotesData.list_of_notes) {
      setNotesData(getNotesData.list_of_notes);
    }
  }, [isNotesDataFetched]);
  useEffect(()=>{
    if(postedNotesData){
      localStorage.setItem('note_id',postedNotesData.note_id)
      navigate("/notes");
      window.location.reload()
    }
  },[postedNotesData])
  const data =
    notesData &&
    notesData.map((item, index) => ({
      ...item,
      id: index + 1, // Assuming you want to start with ID 1
    }));
  const handleCellClick = (e, data) => {
    if (data.row.is_meeting) {
      localStorage.setItem("setNewMeetingClicked", "false");
    } else {
      localStorage.setItem("setNewMeetingClicked", "true");
    }
    console.log("data", data.row.is_meeting);
    if (data && data.row) {
      localStorage.setItem("note_id", data.row.name);
      navigate("/notes");
      window.location.reload();
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "title",
      headerName: "Notes Title",
      width: 500,
      renderCell: (params) => (
        <div
          onClick={(e) => handleCellClick(e, params)}
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "modified",
      headerName: "Last Modified",
      width: 250,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "bi_id",
      headerName: "BI Belongs To",
      width: 250,
    },
    {
      field: "is_meeting",
      headerName: "Linked To Meeting",
      width: 150,
      renderCell: (params) => (
        <div>
          {params.value ? (
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "green",
                marginLeft: "50px",
              }}
            ></div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const dataWithUniqueIds = (notesData) => notesData.name;

  const handleAddClick = () => {
    localStorage.setItem("setNewMeetingClicked", "true");
    dispatch(postNotes("", localStorage.getItem("bi_id"), "", "", "add_new"));
  };
  // const handleCloseButtonClick = () => {
  //   window.history.go(-1);
  // };
  const content = (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Notes
        </div>
        <div
          style={{
            alignItems: "center",
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          <div className="btn-group">
            <button
              className="btn btn-success"
              style={{
                marginBottom: "10px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              onClick={handleAddClick}
            >
              + New
            </button>
          </div>
          {/* <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              marginLeft: "20px",
            }}
            onClick={handleCloseButtonClick}
          >
            <img src={Cancel} width={25} height={25}></img>
          </button> */}
        </div>
      </div>
      <div style={{ width: "100%", height: "75vh" }}>
        <DataGrid
          rows={data}
          getRowId={dataWithUniqueIds}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
        />
      </div>
    </motion.div>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={false}
        showMenu={true}
        showScreen={"notes"}
        showFullScreen={true}
        isMenuCollapse={true}
      />
    );
  }
};

export default ListOfNotes;
